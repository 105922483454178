import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import nl from 'vuetify/es5/locale/nl'

export default new Vuetify({
    lang: {
        locales: {nl},
        current: 'nl'
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            dark: {
                primary: '#00AD83',
                accent: '#F3B517',
                secondary: '#0018A8',
                success: '#4CAF50',
                info: '#2196F3',
                warning: '#FB8C00',
                error: '#FF5252'
            },
            light: {
                primary: '#00AD83',
                accent: '#F3B517',
                secondary: '#0018A8',
                success: '#4CAF50',
                info: '#2196F3',
                warning: '#FB8C00',
                error: '#FF5252'
            }
        },
    },
    icons: {
        iconfont: 'fa',
        values: {
            sort: 'fal fa-long-arrow-up ml-1',
            prev: 'fal fa-chevron-left',
            next: 'fal fa-chevron-right',
            dropdown: 'fal fa-chevron-down',
            expand: 'fal fa-chevron-down',
        }
    },
});
