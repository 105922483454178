<template>
    <v-chip
        pill
        :to="{name:'UserDetail', params: { slug: user.slug }}"
        outlined
    >
        <v-avatar left v-if="user.image">
            <v-img :src="user.image.url"></v-img>
        </v-avatar>
        <v-icon left v-else><!-- TODO: icon doet het nog niet -->
            fal fa-user
        </v-icon>
        {{user.title}}
    </v-chip>
</template>

<script>
export default {
    name: "UserChip",
    props: {
        user: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
