<template>
    <v-container>
        <v-row align="center">
            <v-col>
                <h1 class="text-h1">Kennisbank</h1>
            </v-col>
            <v-col>
                <v-breadcrumbs :items="breadcrumbs" class="float-md-right pa-0"></v-breadcrumbs>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="9" v-show="isLoading" class="order-2">
                <div class="text-center mt-5">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </div>
            </v-col>
            <v-col cols="12" md="8" lg="9" class="mt-n3 order-2" ref="kbContent">
                <div v-if="page">
                    <h2 class="text-h2">{{ page.title }}</h2>
                    <ContentEditorContent :content="page.content" class="knowledge-base-content"></ContentEditorContent>
                    <SignupForm v-if="page.form_configuration" :multiple-entries="page.formMultipleEntries" :entries="page.form_entries" :configuration="page.form_configuration" :expiration-date="page.expirationDate" :show-entries="page.showFormEntries" :submission="page.form_submission" content-type="portal_page" :id="page.form_id" :show-title="false" :is-anonymous="page.anonymousForm"></SignupForm>
                </div>
                <div v-if="!page && !isLoading">
                    <h2>Welkom bij de kennisbank</h2>
                    <p>Klik op een pagina in het menu om deze te bekijken of gebruik de zoekfunctie bovenin de balk.</p>
                </div>
            </v-col>
            <v-col cols="12" md="4" lg="3" class="order-1">
                <v-card class="knowledge-base-nav">
                    <Navigation></Navigation>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Navigation from "../components/KnowledgeBase/Navigation";
import SignupForm from "../components/SignupForm";
import ContentEditorContent from "../components/ContentEditorContent";

export default {
    name: "KnowledgeBase",
    components: {ContentEditorContent, Navigation, SignupForm},
    data: () => ({
        page: null,
        isLoading: false,
        breadcrumbs: [],
    }),
    mounted() {
        this.updatePage();
    },
    watch: {
        '$route'() {
            this.updatePage();
        },
    },
    computed: {
        menuPages() {
            return this.$store.state.pages.menuPages;
        }
    },
    methods: {
        updatePage() {
            this.page = null;

            this.breadcrumbs = [
                {
                    text: 'Kennisbank',
                    disabled: false,
                    to: { name: 'KnowledgeBase' },
                    exact: true
                }
            ];

            let slug = this.$route.params.slug;
            if (slug) {
                this.isLoading = true;
                this.$store.dispatch('getPage', {slug: slug}).then(page => {
                    this.page = page;
                    this.isLoading = false;

                    let parent = this.menuPages.find(p => p.children.some(child => child.title === page.title))
                    if(parent) {
                        this.$store.dispatch('getPage', {slug: parent.url.replace('/kennisbank/', '')}).then(parentPage => {
                            this.breadcrumbs.push(
                                {
                                    text: parentPage.title,
                                    disabled: false,
                                    to: { name: 'KnowledgeBase', params: { slug: parentPage.slug } },
                                }
                            )

                            this.breadcrumbs.push(
                                {
                                    text: page.title,
                                    disabled: true,
                                    to: { name: 'KnowledgeBase', params: { slug: page.slug } },
                                }
                            )
                        })
                    } else {
                        this.breadcrumbs.push(
                            {
                                text: page.title,
                                disabled: true,
                                to: { name: 'KnowledgeBase', params: { slug: page.slug } },
                            }
                        )
                    }

                    this.$nextTick(() => {
                        if (window.location.hash) {
                            const hash = window.location.hash.replace('#', '');
                            const downloadElement = document.getElementById(hash);

                            if (downloadElement) {
                                const rect = downloadElement.getBoundingClientRect();
                                const offsetY = rect.top - 130;

                                window.scrollTo(0, offsetY);

                                downloadElement.classList.add('highlighted-scroll');
                            }
                        }
                    });

                });
            }
        },
    },
}
</script>

<style scoped>

</style>
