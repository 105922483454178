<template>
    <v-container>
        <v-row class="justify-center">
            <v-col sm="5" class="pa-0">
            <h1 class="text-h1 mb-5 text-center">Profiel wijzigen</h1>
                <v-card
                    elevation="2"
                >
                    <v-form ref="form" @submit.prevent="updateProfile">
                        <v-card class="elevation-0">
                            <v-card-text>
<!--                                <p class="px-2 mb-4">Vul een nieuw wachtwoord in voor uw Intranet Bouwmij-Janssen-account.</p>-->
                                <v-alert type="error" :value="error">{{ error }}</v-alert>
                                <v-text-field
                                    type="text"
                                    prepend-icon="far fa-user"
                                    v-model="firstName"
                                    disabled
                                    label="Voornaam"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    type="text"
                                    prepend-icon="far fa-user"
                                    v-model="lastName"
                                    disabled
                                    label="Achternaam"
                                ></v-text-field>
                                <v-text-field
                                    type="text"
                                    prepend-icon="far fa-phone"
                                    v-model="telOffice"
                                    label="Telefoon kantoor"
                                ></v-text-field>
                                <v-text-field
                                    type="text"
                                    prepend-icon="far fa-mobile"
                                    v-model="telMobile"
                                    label="Telefoon mobiel"
                                ></v-text-field>
                                <v-text-field
                                    type="text"
                                    prepend-icon="far fa-city"
                                    v-model="homeTown"
                                    label="Woonplaats"
                                ></v-text-field>
<!--                                <v-date-picker-->
<!--                                    prepend-icon="far fa-birthday-cake"-->
<!--                                    v-model="birthday"-->
<!--                                    label="Geboortedatum"-->
<!--                                ></v-date-picker>-->

                                <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="computedDateFormatted"
                                            label="Geboortedatum"
                                            prepend-icon="far fa-birthday-cake"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="birthday"
                                        @input="menu = false"
                                    ></v-date-picker>
                                </v-menu>

                                <v-select
                                    v-model="absentDays"
                                    label="Afwezig op"
                                    :items="absentDaysOptions"
                                    :multiple="true"
                                    item-value="id"
                                    item-label="label"
                                    prepend-icon="far fa-calendar"
                                ></v-select>
                            </v-card-text>
                            <v-card-actions class="justify-center pb-3">
                                <v-btn class="btn-decorative" type="submit" elevation="0">
                                    <v-icon small class="mr-2">fal fa-check</v-icon>
                                    Opslaan
                                </v-btn>
                            </v-card-actions>
                            <v-card-actions class="justify-center pb-3">
                                <a @click="onClickPasswordChangeButton">Wachtwoord wijzigen</a>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import apiClient from "../api/apiClient";
import { DateTime } from "luxon";

export default {
    name: "Profile",
    data: () => ({
        firstName: null,
        lastName: null,
        telOffice: null,
        telMobile: null,
        homeTown: null,
        birthday: null,
        absentDays: null,
        error: null,
        user: null,
        isSubmitted: false,
        isLoading: true,
        absentDaysOptions: [
            'Maandag',
            'Maandagochtend',
            'Maandagmiddag',
            'Dinsdag',
            'Dinsdagochtend',
            'Dinsdagmiddag',
            'Woensdag',
            'Woensdagochtend',
            'Woensdagmiddag',
            'Donderdag',
            'Donderdagochtend',
            'Donderdagmiddag',
            'Vrijdag',
            'Vrijdagochtend',
            'Vrijdagmiddag',
        ],
        menu: false,
    }),
    mounted() {
        return apiClient.get('/profile').then(response => {
            this.user = response.data
            this.initUser()
        })
    },
    computed: {
        computedDateFormatted () {
            return this.formatDate(this.birthday)
        },
    },
    methods: {
        initUser() {
            this.firstName = this.user ? this.user.firstName : null
            this.lastName = this.user ? this.user.lastName : null
            this.telOffice = this.user ? this.user.tel_office : null
            this.telMobile = this.user ? this.user.tel_mobile : null
            this.homeTown = this.user ? this.user.home_town : null
            this.birthday = this.user && this.user.birthday ? DateTime.fromISO(this.user.birthday).toFormat('yyyy-MM-dd') : null
            this.absentDays = this.user ? this.user.absent_days : null
            this.isLoading = false
        },
        onClickPasswordChangeButton() {
            this.$router.push({name:'ChangePassword'});
        },
        updateProfile() {
            if (!this.isSubmitted && this.$refs.form.validate()) {
                this.isSubmitted = true;

                let formData = new FormData();
                formData.append('telOffice', this.telOffice)
                formData.append('telMobile', this.telMobile)
                formData.append('homeTown', this.homeTown)
                if (this.birthday === null) {
                    this.birthday = ''
                }
                formData.append('birthday', this.birthday)
                formData.append('absentDays', this.absentDays)

                return apiClient.post('/user/edit', formData).then(response => {
                    if (response.status === 200) {
                        this.$store.dispatch('showSnackbar', {color: 'success', text: 'Je gegevens zijn aangepast'});
                        this.isSuccess = true;
                    } else {
                        console.error(response.data.error);
                        this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige gegevens'});
                        this.isSubmitted = false;
                    }
                }).catch(() => {
                    this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige gegevens'});
                    this.isSubmitted = false;
                });
            }
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
    }
}
</script>

<style scoped>

</style>
