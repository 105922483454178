<template>
    <v-btn text @click="back" color="primary" class="ml-n3 mb-4">
        <v-icon small class="mr-2">fal fa-long-arrow-left</v-icon>
        Terug
    </v-btn>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: "BackButton",
    computed: {
        ...mapState({
            previousRoute: state => state.app.previousRoute,
        })
    },
    props: {
        route: {
            type: Object,
            required: true
        }
    },
    methods: {
        back () {
            if (this.previousRoute) {
                this.$router.go(-1);
            } else {
                this.$router.push(this.route);
            }
        },
    }
}
</script>

<style scoped>

</style>
