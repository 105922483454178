export default {
    /**
     * Returns the base search results for the given router.
     */
    getBaseSearchResultsForRouter(router, query) {
        let results = [];
        router.options.routes.forEach(route => {
            if (route.meta && route.meta.search_label && (!query || route.meta.search_label.toLowerCase().includes(query.toLowerCase()))) {
                results.push({
                    label: route.meta.search_label,
                    group: 'Hoofdmenu',
                    type: 'route',
                    route: route.name,
                });
            }
        });
        return results;
    },

    /**
     * Navigates to the search result.
     */
    goToSearchResult(router, result) {
        if (result.type === 'route') {
            router.push({name: result.route});
        } else if (result.type === 'portal_page') {
            const splittedSlug = result.slug.split('#');
            let hash = null;
            if(splittedSlug[1]) {
                hash = splittedSlug[1];
            }

            router.push({name: 'KnowledgeBaseDetail', params: { slug: splittedSlug[0] }, hash: '#'+hash });
        } else if (result.type === 'portal_news') {
            router.push({name: 'NewsDetail', params: { slug: result.slug }});
        } else if (result.type === 'portal_project') {
            router.push({name: 'ProjectDetail', params: { id: result.id }});
        } else if (result.type === 'portal_improvement') {
            router.push({name: 'ImprovementDetail', params: { number: result.number }});
        } else if (result.type === 'portal_event') {
            router.push({name: 'EventDetail', params: { slug: result.slug }});
        } else if (result.type === 'photoalbum') {
            router.push({name: 'PhotoalbumDetail', params: { slug: result.slug }});
        } else if (result.type === 'portal_employee') {
            router.push({name: 'UserDetail', params: { slug: result.slug }});
        }
    },
};
