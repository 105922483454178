<template>
    <v-container v-if="project">
        <v-row class="align-center">
            <v-col class="pa-0" cols="12" sm="8">
                <h1 class="text-h1">
                    Project #{{ project.project_no }}
                </h1>
                <h2 class="text-h2">{{ project.name }}</h2>
            </v-col>
<!--            <v-col cols="12" sm="4" class="pa-0 text-sm-right text-h1">-->
<!--                <v-chip>-->
<!--                    {{ (project.status == 'in-progress') ? 'In uitvoering' : 'In ontwikkeling' }}-->
<!--                </v-chip>-->
<!--            </v-col>-->
        </v-row>
        <hr class="mt-5 mb-4" />
        <div class="text--secondary" v-if="project.description" v-html="project.description"></div>

        <v-row v-if="project.project_leads && project.project_leads.length > 0" class="align-center">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Projectleiders:</strong>
            </v-col>
            <v-col class="pt-0 pb-0">
                <v-chip-group>
                    <UserChip v-for="(lead, index) in project.project_leads" :user="lead" :key="index"></UserChip>
                </v-chip-group>
            </v-col>
        </v-row>

        <v-row v-if="project.planners && project.planners.length > 0" class="align-center">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Werkvoorbereiders:</strong>
            </v-col>
            <v-col class="pt-0 pb-0">
                <v-chip-group>
                    <UserChip v-for="(planner, index) in project.planners" :user="planner" :key="index"></UserChip>
                </v-chip-group>
            </v-col>
        </v-row>

        <v-row v-if="project.foremans && project.foremans.length > 0" class="align-center">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Uitvoerders:</strong>
            </v-col>
            <v-col class="pt-0 pb-0">
                <v-chip-group>
                    <UserChip v-for="(foreman, index) in project.foremans" :user="foreman" :key="index"></UserChip>
                </v-chip-group>
            </v-col>
        </v-row>

        <v-row v-if="project.assistent_foremans && project.assistent_foremans.length > 0" class="align-center">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Assistent uitvoerders:</strong>
            </v-col>
            <v-col class="pt-0 pb-0">
                <v-chip-group>
                    <UserChip v-for="(foreman, index) in project.assistent_foremans" :user="foreman" :key="index"></UserChip>
                </v-chip-group>
            </v-col>
        </v-row>

        <v-row v-if="project.contacts.length > 0" class="align-center">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Contactpersonen:</strong>
            </v-col>
            <v-col class="pt-0 pb-0">
                <v-chip-group>
                    <UserChip v-for="(contact, index) in project.contacts" :user="contact" :key="index"></UserChip>
                </v-chip-group>
            </v-col>
        </v-row>

        <hr class="mt-5 mb-4" />
        <BackButton :route="{name: 'ProjectList'}" />

<!--        <v-row v-if="project">-->
<!--            <v-col>-->
<!--                <v-card>-->
<!--                    <pre>{{project}}</pre>-->
<!--                </v-card>-->
<!--            </v-col>-->
<!--        </v-row>-->
    </v-container>
</template>

<script>
import BackButton from "../components/BackButton";
import UserChip from "@/components/UserChip";
export default {
    name: "ProjectDetail",
    components: {BackButton, UserChip},
    mounted () {
        this.loadProjectDetail();
    },
    data: () => ({
        project: null
    }),
    watch: {
        '$route.params.id' () {
            this.loadProjectDetail();
        }
    },
    methods: {
        loadProjectDetail() {
            let id = this.$route.params.id;
            this.$store.dispatch('getProjectDetail', { id: id }).then(project => {
                this.project = project;
            })
        }
    }
}
</script>

<style scoped>

</style>
