import apiClient from "@/api/apiClient";

// initial state
const state = {
    newsForms: [],
    eventsForms: [],
}

// getters
const getters = {
}

// actions
const actions = {
    getNewsForms({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.newsForms.length >= 1) {
            return state.newsForms;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/opgave-nieuws')
            .then(response => {
                commit('SET_NEWS_FORMS', response.data);
                return response.data;
            })
    },
    getEventsForms({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.eventsForms.length >= 1) {
            return state.eventsForms;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/opgave-activiteiten')
            .then(response => {
                commit('SET_EVENTS_FORMS', response.data);
                return response.data;
            })
    },
    // getPhotoalbum({commit, state}, { slug }) {
    //     let photoalbum = state.viewPhotoalbum.find(photoalbum => photoalbum.slug === slug);
    //     if (photoalbum) {
    //         return photoalbum;
    //     }
    //
    //     // TODO: Ook in localstorage met expiration
    //     return apiClient.get('/photoalbum/album/'+slug)
    //         .then(response => {
    //             commit('ADD_VIEW_PHOTOALBUM', response.data);
    //             return response.data;
    //         })
    // },
}

// mutations
const mutations = {
    SET_NEWS_FORMS(state, newsForms) {
       state.newsForms = newsForms;
    },
    SET_EVENTS_FORMS(state, eventsForms) {
        state.eventsForms = eventsForms;
    },
    // ADD_VIEW_PHOTOALBUM(state, photoalbum) {
    //     state.viewPhotoalbum.push(photoalbum);
    // },
}

export default {
    state,
    getters,
    actions,
    mutations
}
