<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" md="6">
                <h1 class="text-h1">Fotoalbums</h1>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pt-md-4 pb-5 pb-md-3">
                <v-breadcrumbs :items="breadcrumbs" class="float-md-right pa-0"></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="4" md="3" v-for="item in paginatedData" :key="item.id">
                <v-card
                    outlined
                    class="photoalbum-card"
                    v-on:click.stop.prevent="openPhotoalbum(item)"
                >
                    <div class="img">
                        <v-img
                            :aspect-ratio="($vuetify.breakpoint.smAndUp) ? '1' : '2'"
                            :src="item.thumbnail ? item.thumbnail.url : null"
                        ></v-img>
                        <div class="diamond-icon">
                            <v-icon>
                                fal fa-search
                            </v-icon>
                        </div>
                    </div>
                    <v-card-title>
                        {{ item.title }}
                    </v-card-title>
                    <!--                    <pre>{{ item }}</pre>-->
                </v-card>
            </v-col>
        </v-row>
        <v-pagination v-model="pagination.pageNumber" :length="pages" :total-visible="10" v-if="items.length > pagination.itemsPerPage"
                      @input="nextPage" class="mt-5 mb-5"></v-pagination>
    </v-container>
</template>

<script>
export default {
    name: "PhotoalbumList",
    mounted() {
        this.$store.dispatch('getPhotoalbums')

        if (localStorage.pageContext === 'photoalbum') {
            this.pagination.pageNumber = parseInt(localStorage.page);
        }
        localStorage.page = this.pagination.pageNumber;
        localStorage.pageContext = 'photoalbum';
    },
    data: () => ({
        pagination: {
            pageNumber: 1,
            itemsPerPage: 8
        },
        breadcrumbs: [
            {
                text: 'Kennisbank',
                disabled: false,
                to: { name: 'KnowledgeBase' },
                exact: true
            },
            {
                text: 'Fotoalbums',
                disabled: true
            }
        ]
    }),
    watch: {
        'pagination.pageNumber' () {
            localStorage.page = this.pagination.pageNumber;
            localStorage.pageContext = 'photoalbum';
        },
    },
    methods: {
        openPhotoalbum(album) {
            this.$router.push({name: 'PhotoalbumDetail', params: {slug: album.slug}})
        },
        nextPage(page) {
            this.pagination.pageNumber = page;
        }
    },
    computed: {
        items() {
            return this.$store.state.photoalbums.photoalbums;
        },
        pages() {
            return Math.ceil(this.items.length / this.pagination.itemsPerPage)
        },
        paginatedData() {
            const start = this.pagination.pageNumber * this.pagination.itemsPerPage - this.pagination.itemsPerPage, //sets the correct start on data
                end = start + this.pagination.itemsPerPage;
            return this.items.slice(start, end);
        }
    }
}
</script>

<style scoped>

</style>
