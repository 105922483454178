import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/vue-luxon';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-pro/css/all.css'

Vue.config.productionTip = false

console.log('%cBouwmij Janssen %cIntranet App\r\n' +
    '%cVersion: %c' + process.env.VUE_APP_VERSION + '\r\n' +
    '%cAPI: %c' + process.env.VUE_APP_API_URL + '\r\n' +
    //'%cLanguage: %c' + i18n.locale + ' %c(' + navigator.languages + ') \r\n'+
    '%cDeveloped by: %cInternetbureau Forwart%c - %chttps://www.forwart.nl/',
    'color: #0EA083; font-weight: bold; font-size: 1.3em;',
    'color: #28358D; font-size: 1.3em;',
    'color: #888', 'color: #999;',
    'color: #888', 'color: #999;',
    //'color: #888', 'color: #28358D;', 'color: #ccc',
    'color: #888', 'color: #0EA083; font-weight: bold', 'color: #333', 'color: #00f; text-decoration: underline;'
);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
