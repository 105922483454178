import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Dashboard from '../views/Dashboard.vue'
import Calendar from '../views/Calendar.vue'
import EventDetail from '../views/EventDetail.vue'
import NewsList from '../views/NewsList.vue'
import NewsDetail from '../views/NewsDetail.vue'
import ImprovementList from '../views/ImprovementList.vue'
import ImprovementDetail from '../views/ImprovementDetail.vue'
import ImprovementSubmit from '../views/ImprovementSubmit.vue'
import ImprovementEdit from '../views/ImprovementEdit.vue'
import ProjectList from '../views/ProjectList.vue'
import ProjectDetail from '../views/ProjectDetail.vue'
import KnowledgeBase from '../views/KnowledgeBase.vue'
import UserDetail from '../views/UserDetail.vue'
import UserList from '../views/UserList.vue'
import PhotoalbumList from '../views/PhotoalbumList.vue'
import RegistrationFormList from "../views/RegistrationFormList";
import PhotoalbumDetail from '../views/PhotoalbumDetail.vue'
import ChangePassword from '../views/ChangePassword.vue';
import Search from '../views/Search.vue';
import Profile from '../views/Profile.vue';
import PersonalInfoSubmit from "../views/PersonalInfoSubmit";

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/wachtwoord-aanvragen',
        name: 'ForgotPassword',
        component: ForgotPassword
    },
    {
        path: '/wachtwoord-reset/:token',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {search_label: 'Dashboard', requiresAuth: true},
    },
    {
        path: '/profiel',
        name: 'Profile',
        component: Profile,
        meta: {requiresAuth: true}
    },
    {
        path: '/wachtwoord-wijzigen',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {requiresAuth: true}
    },
    {
        path: '/wijziging-persoonlijke-gegevens',
        name: 'PersonalInfoSubmit',
        component: PersonalInfoSubmit,
        meta: {requiresAuth: true}
    },
    {
        path: '/agenda',
        name: 'Calendar',
        component: Calendar,
        meta: {search_label: 'Agenda', requiresAuth: true}
    },
    {
        path: '/agenda/:slug',
        name: 'EventDetail',
        component: EventDetail,
        meta: {requiresAuth: true}
    },
    {
        path: '/nieuws',
        name: 'NewsList',
        component: NewsList,
        meta: {search_label: 'Nieuws', requiresAuth: true}
    },
    {
        path: '/nieuws/:slug',
        name: 'NewsDetail',
        component: NewsDetail,
        meta: {requiresAuth: true}
    },
    {
        path: '/verbeterplannen',
        name: 'ImprovementList',
        component: ImprovementList,
        meta: {search_label: 'Verbeterplannen', requiresAuth: true}
    },
    {
        path: '/verbeterplannen/indienen',
        name: 'ImprovementSubmit',
        component: ImprovementSubmit,
        meta: {requiresAuth: true}
    },
    {
        path: '/verbeterplannen/:number',
        name: 'ImprovementDetail',
        component: ImprovementDetail,
        meta: {requiresAuth: true}
    },
    {
        path: '/verbeterplannen/bewerken/:number',
        name: 'ImprovementEdit',
        component: ImprovementEdit,
        meta: {requiresAuth: true}
    },
    {
        path: '/projecten',
        name: 'ProjectList',
        component: ProjectList,
        meta: {search_label: 'Projecten', requiresAuth: true}
    },
    {
        path: '/projecten/:id',
        name: 'ProjectDetail',
        component: ProjectDetail,
        meta: {requiresAuth: true}
    },
    {
        path: '/kennisbank',
        name: 'KnowledgeBase',
        component: KnowledgeBase,
        meta: {search_label: 'Kennisbank', requiresAuth: true}
    },
    {
        path: '/kennisbank/:slug(.*)',
        name: 'KnowledgeBaseDetail',
        component: KnowledgeBase,
        meta: {requiresAuth: true}
    },
    {
        path: '/medewerkers',
        name: 'UserList',
        component: UserList,
        meta: {search_label: 'Medewerkers', requiresAuth: true}
    },
    {
        path: '/medewerkers/:slug(.*)',
        name: 'UserDetail',
        component: UserDetail,
        meta: {requiresAuth: true}
    },
    {
        path: '/fotoalbums',
        name: 'PhotoalbumList',
        component: PhotoalbumList,
        meta: {search_label: 'Fotoalbums', requiresAuth: true}
    },
    {
        path: '/opgave',
        name: 'RegistrationFormList',
        component: RegistrationFormList,
        meta: {search_label: 'Opgave', requiresAuth: true}
    },
    {
        path: '/fotoalbums/:slug(.*)',
        name: 'PhotoalbumDetail',
        component: PhotoalbumDetail,
        meta: {requiresAuth: true}
    },
    {
        path: '/zoeken/:query?',
        name: 'Search',
        component: Search,
        meta: {requiresAuth: true}
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (from.name != null) {
        store.commit('SET_PREVIOUS_ROUTE', from);
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.state.auth.isLoggedIn) {
            store.commit('SET_LOGIN_REDIRECT_ROUTE', to);
            next({
                name: 'Login'
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})


export default router
