import apiClient from "@/api/apiClient";

// initial state
const state = {
    projects: [],
}

// getters
const getters = {

}

// actions
const actions = {
    getProjects({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.projects.length >= 1) {
            return state.projects;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/projects')
            .then(response => {
                commit('SET_PROJECTS', response.data);
                return response.data;
            })
    },
    getProjectDetail({state, dispatch}, { id }) {
        id = parseInt(id)

        let findById = (projects) => {
            return projects.find(project => project.id === id);
        }

        if (state.projects.length === 0) {
            return dispatch('getProjects').then(projects => {
                return findById(projects);
            });
        } else {
            return findById(state.projects);
        }
    },
}

// mutations
const mutations = {
    SET_PROJECTS(state, projects) {
       state.projects = projects;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
