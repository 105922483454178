<template>
    <div v-html="content" ref="content"></div>
</template>

<script>
import {
    Chart,
    BarElement,
    BarController,
    LinearScale,
    CategoryScale,
    Filler,
    Legend,
    Title,
    Tooltip
} from 'chart.js';

Chart.register(
    BarElement,
    BarController,
    LinearScale,
    CategoryScale,
    Filler,
    Legend,
    Title,
    Tooltip
);

export default {
    name: "ContentEditorContent",
    props: {
        content: {
            type: String,
            required: true,
        },
    },
    mounted () {
        this.$refs.content.querySelectorAll('.form-charts .js-chart').forEach(chart => {
            new Chart(chart.querySelector('canvas'), {
                type: 'bar',
                data: {
                    labels: JSON.parse(chart.dataset.labels),
                    datasets: [{
                        label: 'Aantal',
                        data: JSON.parse(chart.dataset.data),
                        backgroundColor: [
                            chart.dataset.color,
                        ],
                    }],
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                stepSize: 1
                            },
                        }
                    },
                }
            });
        });
    },
}
</script>

<style scoped>

</style>
