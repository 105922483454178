<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" md="6">
                <h1 class="text-h1">Smoelenboek</h1>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pt-md-4 pb-5 pb-md-3">
                <v-breadcrumbs :items="breadcrumbs" class="float-md-right pa-0"></v-breadcrumbs>
            </v-col>
        </v-row>

        <v-card class="ps-4 py-0 my-0">
            <v-row>
                <v-radio-group
                    class="py-0 my-0"
                    v-model="selectedFilter"
                    row
                    hide-details
                >
                    <v-col>
                        <v-radio label="Alles" value="all"></v-radio>
                    </v-col>
                    <v-col>
                        <v-radio label="Medewerkers" value="employees"></v-radio>
                    </v-col>
                    <v-col>
                        <v-radio label="Leerlingen" value="students"></v-radio>
                    </v-col>
                    <v-col>
                        <v-radio label="Stagiair(e)s" value="interns"></v-radio>
                    </v-col>
                </v-radio-group>
            </v-row>
        </v-card>

        <!--Employees-->
        <v-row align="center" v-if="filteredUsers.length > 0 && (selectedFilter === 'employees' || selectedFilter === 'all')">
            <v-col cols="12" md="6">
                <h2 class="text-h2">Medewerkers</h2>
            </v-col>
        </v-row>

        <v-row v-if="selectedFilter === 'employees' || selectedFilter === 'all'" class="team-overview">
            <v-col v-for="user in paginatedData" :key="user.id" cols="12" sm="6" md="4" lg="3" style="cursor: pointer;" class="mb-5" @click="$router.push({name: 'UserDetail', params:{slug: user.slug}})">
                <user-card :user="user"></user-card>
            </v-col>
        </v-row>
        <v-pagination v-model="pagination.pageNumber" :length="pages" :total-visible="10" v-if="filteredUsers.length > pagination.itemsPerPage && (selectedFilter === 'employees' || selectedFilter === 'all')"
                      @input="nextPage" class="mt-5 mb-5"></v-pagination>

        <!--Students-->
        <v-row align="center" v-if="students.length > 0 && (selectedFilter === 'students' || selectedFilter === 'all')">
            <v-col cols="12" md="6">
                <h2 class="text-h2">Leerlingen</h2>
            </v-col>
        </v-row>

        <v-row class="team-overview" v-if="students.length > 0 && (selectedFilter === 'students' || selectedFilter === 'all')">
            <v-col v-for="user in paginatedStudentData" :key="user.id" cols="12" sm="6" md="4" lg="3" style="cursor: pointer;" class="mb-5" @click="$router.push({name: 'UserDetail', params:{slug: user.slug}})">
                <user-card :user="user"></user-card>
            </v-col>
        </v-row>
        <v-pagination v-model="studentPagination.pageNumber" :length="studentPages" :total-visible="10" v-if="students.length > studentPagination.itemsPerPage && (selectedFilter === 'students' || selectedFilter === 'all')"
                      @input="nextStudentPage" class="mt-5 mb-5"></v-pagination>

        <!--Interns-->
        <v-row align="center" v-if="interns.length > 0 && (selectedFilter === 'interns' || selectedFilter === 'all')">
            <v-col cols="12" md="6">
                <h2 class="text-h2">Stagiair(e)s</h2>
            </v-col>
        </v-row>

        <v-row class="team-overview" v-if="interns.length > 0 && (selectedFilter === 'interns' || selectedFilter === 'all')">
            <v-col v-for="user in paginatedInternData" :key="user.id" cols="12" sm="6" md="4" lg="3" style="cursor: pointer;" class="mb-5" @click="$router.push({name: 'UserDetail', params:{slug: user.slug}})">
                <user-card :user="user"></user-card>
            </v-col>
        </v-row>
        <v-pagination v-model="internPagination.pageNumber" :length="internPages" :total-visible="10" v-if="interns.length > internPagination.itemsPerPage && (selectedFilter === 'interns' || selectedFilter === 'all')"
                      @input="nextInternPage" class="mt-5 mb-5"></v-pagination>
    </v-container>
</template>

<script>
import {mapState} from 'vuex';
import UserCard from "../components/User/Card";

export default {
    name: "UserList",
    components: {UserCard},
    mounted() {
        this.$store.dispatch('getUsers', {forceReload: true})

        if (localStorage.pageContext === 'user') {
            this.pagination.pageNumber = parseInt(localStorage.page);
        }
        localStorage.page = this.pagination.pageNumber;
        localStorage.pageContext = 'user';

        //student
        if (localStorage.studentPageContext === 'student') {
            this.studentPagination.pageNumber = parseInt(localStorage.studentPage);
        }
        localStorage.studentPage = this.studentPagination.pageNumber;
        localStorage.studentPageContext = 'student';

        //intern
        if (localStorage.internPageContext === 'intern') {
            this.internPagination.pageNumber = parseInt(localStorage.internPage);
        }
        localStorage.internPage = this.internPagination.pageNumber;
        localStorage.internPageContext = 'intern';
    },
    data: () => ({
        pagination: {
            pageNumber: 1,
            itemsPerPage: 12
        },
        studentPagination: {
            pageNumber: 1,
            itemsPerPage: 4
        },
        internPagination: {
            pageNumber: 1,
            itemsPerPage: 4
        },
        breadcrumbs: [
            {
                text: 'Kennisbank',
                disabled: false,
                to: { name: 'KnowledgeBase' },
                exact: true
            },
            {
                text: 'Smoelenboek',
                disabled: true
            }
        ],
        selectedFilter: 'all',
    }),
    watch: {
        'pagination.pageNumber' () {
            localStorage.page = this.pagination.pageNumber;
            localStorage.pageContext = 'user';
        },
        'studentPagination.pageNumber' () {
            localStorage.studentPage = this.studentPagination.pageNumber;
            localStorage.studentPageContext = 'student';
        },
        'internPagination.pageNumber' () {
            localStorage.internPage = this.internPagination.pageNumber;
            localStorage.internPageContext = 'intern';
        },
    },
    computed: {
        pages() {
            return Math.ceil(this.users.length / this.pagination.itemsPerPage)
        },
        studentPages() {
            return Math.ceil(this.students.length / this.studentPagination.itemsPerPage)
        },
        internPages() {
            return Math.ceil(this.interns.length / this.internPagination.itemsPerPage)
        },
        paginatedData() {
            const start = this.pagination.pageNumber * this.pagination.itemsPerPage - this.pagination.itemsPerPage, //sets the correct start on data
                end = start + this.pagination.itemsPerPage;
            return this.filteredUsers.slice(start, end);
        },
        paginatedStudentData() {
            const start = this.studentPagination.pageNumber * this.studentPagination.itemsPerPage - this.studentPagination.itemsPerPage, //sets the correct start on data
                end = start + this.studentPagination.itemsPerPage;
            return this.students.slice(start, end);
        },
        paginatedInternData() {
            const start = this.internPagination.pageNumber * this.internPagination.itemsPerPage - this.internPagination.itemsPerPage, //sets the correct start on data
                end = start + this.internPagination.itemsPerPage;
            return this.interns.slice(start, end);
        },
        filteredUsers () {
            return this.users.filter(user => user.email !== 'support@forwart.nl' && !user.intern && !user.student);
        },
        interns () {
            return this.users.filter(user => user.email !== 'support@forwart.nl' && user.intern);
        },
        students () {
            return this.users.filter(user => user.email !== 'support@forwart.nl' && user.student);
        },
        ...mapState({
            users: state => state.users.users
        })
    },
    methods: {
        nextPage(page) {
            this.pagination.pageNumber = page;
        },
        nextStudentPage(page) {
            this.studentPagination.pageNumber = page;
        },
        nextInternPage(page) {
            this.internPagination.pageNumber = page;
        },
    },

}
</script>

<style scoped>

</style>
