import apiClient from "@/api/apiClient";

// initial state
const state = {
    events: [],
    files: [],
    activeCategories: ['specialDate', 'event', 'free', 'holiday', 'freeOptional', 'holidayDay', 'other'],
    selectedDate: '',
}

// getters
const getters = {
    getEventColor: () => (event) => {
        switch (event.type) {
            case 'specialDate':
                return 'secondary';
            case 'event':
                return 'purple lighten-1';
            case 'free':
                return 'accent';
            case 'holiday':
                return 'red darken-4';
            case 'other':
                return 'primary darken-4';
            case 'freeOptional':
                return 'primary';
            case 'holidayDay':
                return 'cyan darken-1';
            default:
                return 'primary';
        }

    },
}

// actions
const actions = {
    getEvents({ commit, rootGetters, dispatch }, { start, end }) {
        // load events from api
        // merge verjaardagen/jubilea
        let usersPromise = dispatch('getUsers', { forceReload: false})
            .then(() => {
                return rootGetters.getSpecialDateEvents(start, end);
            });
        let calendarPromise = apiClient.get('/calendar', { params: { start, end }})
            .then(response => {
                return response.data
            })
            .then(({events, files}) => {
                events = events.map(event => {
                    return {
                        name: event.title,
                        start: new Date(event.start_datetime),
                        end: new Date(event.end_datetime),
                        type: event.type,
                        timed: !event.all_day,
                        event: event
                    }
                })
                commit('ADD_EVENTS', events);
                commit('SET_FILES', files);

                return events;
            })
        return Promise.all([calendarPromise, usersPromise])
            .then(result => {
                let calendarEvents = result[0];
                let usersEvents = result[1];
                return [...calendarEvents, ...usersEvents];
            });
    },
    getEvent({state}, { slug }) {

        let cachedEvent = state.events.find(event => event.slug === slug);
        if (cachedEvent) {
            return cachedEvent
        }

        return apiClient.get('/events/intranet/agenda/'+slug)
            .then(response => {
                return response.data;
            })
    },
}

// mutations
const mutations = {
    ADD_EVENTS(state, events) {
        state.events = events;
    },
    SET_FILES(state, files) {
        state.files = files;
    },
    SET_ACTIVE_CATEGORIES(state, categories) {
        state.activeCategories = categories;
    },
    SET_SELECTED_DATE(state, date) {
        state.selectedDate = date;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
