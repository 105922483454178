<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-row class="login-form elevation-12">
                <v-col sm="5" class="primary intro">
                    <h1 class="pt-3">Wachtwoord vergeten</h1>
                    <p class="px-2" v-if="!isSuccess">Vul uw Intranet Bouwmij-Janssen-gebruikersnaam in, u zult een e-mail toegestuurd krijgen met verdere instructies.</p>
                    <v-btn outlined color="secondary" small :to="{name: 'Login'}" class="mb-3 mt-4">Terug naar inloggen</v-btn>
                </v-col>
                <v-col sm="7" class="pl-0 pr-0">
                    <div class="animated-logo mx-5">
                        <lottie-animation
                            path="logo.json"
                            :loop="false"
                        />
                    </div>
<!--                    <v-img-->
<!--                        contain-->
<!--                        height="120"-->
<!--                        src="../assets/images/bouwmij-janssen-logo-slogan.svg"-->
<!--                        class="mx-4 d-none d-sm-flex"-->
<!--                    ></v-img>-->
                    <v-alert
                      icon="fal fa-info"
                      prominent
                      text
                      type="info"
                      class="mx-3"
                      v-if="isSuccess"
                    >
                      U ontvangt een e-mail bericht met de instructies om een nieuw wachtwoord aan te vragen. Was dit niet de bedoeling? Dan hoeft u verder niets met dit e-mail bericht te doen en zal uw wachtwoord ongewijzigd blijven.
                    </v-alert>
                    <v-form v-else ref="form" @submit.prevent="forgotPassword">
                        <v-card class="elevation-0">
                            <v-card-text>
                                <v-alert type="error" :value="error">{{ error }}</v-alert>
                                <v-text-field
                                    type="text"
                                    prepend-icon="far fa-user"
                                    v-model="username"
                                    label="Gebruikersnaam"
                                    :rules="usernameRules"
                                    required
                                ></v-text-field>
                            </v-card-text>
                            <v-card-actions class="justify-center pb-3">
                                <v-btn large type="submit" color="primary">
                                    <v-icon
                                        small
                                        class="mr-2"
                                      >
                                        fal fa-sign-in
                                    </v-icon>
                                    Aanvragen
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-col>
            </v-row>
        </v-layout>
    </v-container>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
    name: "ForgotPassword",
    data: () => ({
        username: '',
        usernameRules: [
            v => !!v || 'Verplicht'
        ],
        error: null,
        isSuccess: false,
        isSubmitted: false,
    }),
    components: {
      LottieAnimation
    },
    methods: {
        forgotPassword() {
            // Check if form is valid
            if (!this.isSubmitted && this.$refs.form.validate()) {
                this.isSubmitted = true;
                // Submit data to auth store
                this.$store.dispatch('forgotPassword', {
                    username: this.username,
                }).then((success) => {
                    if (success) {
                        this.isSuccess = true;
                    } else {
                        this.isSubmitted = false;
                        return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Serverfout'});
                    }
                }).catch((e) => {
                    this.isSubmitted = false;
                    console.error(e);
                    return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Serverfout'});
                })
            }
        }
    }

}
</script>

<style scoped>

</style>
