<template>
    <v-container>
        <v-row class="justify-center">
            <v-col sm="5" class="pa-0">
                <v-text-field
                    v-model="query"
                    :append-icon="query ? 'fal fa-search' : ''"
                    placeholder="Zoeken..."
                    @click:append="search"
                    v-on:keyup.enter="search"
                    :loading="isSearching"
                    width="250"
                ></v-text-field>
                <h1 class="mb-3">Zoekresultaten</h1>
                <div class="text-center mt-5" v-if="isSearching">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </div>
                <p v-if="!isSearching && searchResults.length === 0">Geen zoekresultaten...</p>
                <v-list two-line v-if="!isSearching && searchResults.length > 0" elevation="1" nav class="pa-0">
                    <v-list-item v-for="(result, index) in searchResults" :key="index" @click="goToSearchResult(result)" class="mb-n1">
                        <v-list-item-content class="px-2">
                            <v-list-item-title>{{ result.label }}</v-list-item-title>
                            <v-list-item-subtitle v-if="result.group">{{ result.group }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import apiClient from "@/api/apiClient";
import routeSearcher from "../router/routeSearcher";

export default {
    name: "Search",
    data: () => ({
        isSearching: false,
        query: '',
        searchResults: [],
    }),
    mounted() {
        this.query = this.$route.params.query;
        if (this.query) {
            this.search();
        }
    },
    watch: {
        '$route.params' () {
            this.query = this.$route.params.query;
            if (this.query) {
                this.search();
            }
        },
    },
    methods: {
        search() {
            if (this.isSearching) {
                return;
            }

            // Ignore if empty string
            if (this.query === null || this.query.trim() === '') {
                return;
            }

            this.searchResults = [];
            this.isSearching = true;

            let searchResults = routeSearcher.getBaseSearchResultsForRouter(this.$router, this.query);
            apiClient.get('/search', { params: {
                    query: this.query,
                }}).then(response => {
                if (response.status === 200) {
                    this.searchResults = searchResults.concat(response.data);
                } else {
                    console.error(response.data.error);
                    return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige zoekopdracht'});
                }
            }).catch(() => {
                return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige zoekopdracht'});
            }).finally(() => {
                this.isSearching = false;
            });
        },
        goToSearchResult(result) {
            routeSearcher.goToSearchResult(this.$router, result);
        },
    },
}
</script>

<style scoped>

</style>
