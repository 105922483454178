<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" md="6">
                <h1 class="text-h1">Opgave</h1>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pt-md-4 pb-5 pb-md-3">
                <v-breadcrumbs :items="breadcrumbs" class="float-md-right pa-0"></v-breadcrumbs>
            </v-col>
        </v-row>
<!--        <v-row v-if="!isLoading">-->
<!--            <v-col class="text-right d-sm-flex">-->
<!--                <v-text-field-->
<!--                    v-model="search"-->
<!--                    append-icon="fal fa-search"-->
<!--                    label="Zoeken..."-->
<!--                    single-line-->
<!--                    hide-details-->
<!--                    class="shrink"-->
<!--                ></v-text-field>-->
<!--            </v-col>-->
<!--        </v-row>-->
        <v-row v-for="item in paginatedData" :key="item.id">
            <v-col>
                <v-card
                    flat
                    class="news-card"
                >
                    <v-row class="align-center flex-wrap">
                        <v-col sm="12" md="4" lg="3" class="pb-0">
                            <v-img
                                v-if="item.image"
                                :src="item.image.url"
                                max-width="100%"
                                :alt="item.title"
                                class="d-sm-none d-md-flex"
                            ></v-img>
                            <v-img
                                v-if="item.image"
                                :src="item.image.url"
                                max-height="200px"
                                :alt="item.title"
                                class="d-none d-sm-flex d-md-none"
                            ></v-img>
                        </v-col>
                        <v-col sm="12" md="8" lg="9">
                            <div class="content">
                                <v-card-subtitle class="pb-0">
                                    <span v-if="item.date">{{ item.date|luxon('dd-MM-yyyy') }}</span>
                                    <span v-else>{{ item.start_datetime|luxon('dd-MM-yyyy') }}</span>
                                </v-card-subtitle>
                                <v-card-title class="pt-2">
                                    <router-link v-if="item.type == 'event'" :to="{name: 'EventDetail', params: {slug: item.slug}}">{{
                                            item.title
                                        }}
                                    </router-link>
                                    <router-link v-else :to="{name: 'NewsDetail', params: {slug: item.slug}}">{{
                                            item.title
                                        }}
                                    </router-link>
                                </v-card-title>
                                <v-card-text>
                                    <div class="mb-4">{{ item.summary }}</div>
                                    <div class="d-flex align-center">
                                        <v-btn v-if="item.content_type == 'portal_event'" class="btn-decorative"
                                               :to="{name: 'EventDetail', params: {slug: item.slug}}" elevation="0">
                                            Lees meer
                                            <v-icon small class="ml-2">fal fa-arrow-right</v-icon>
                                        </v-btn>
                                        <v-btn v-else class="btn-decorative"
                                               :to="{name: 'NewsDetail', params: {slug: item.slug}}" elevation="0">
                                            Lees meer
                                            <v-icon small class="ml-2">fal fa-arrow-right</v-icon>
                                        </v-btn>
                                    </div>
                                </v-card-text>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: "RegistrationFormList",
    mounted() {
        this.$store.dispatch('getNewsForms')
        this.$store.dispatch('getEventsForms')
    },
    data: () => ({
        pagination: {
            pageNumber: 1,
            itemsPerPage: 8
        },
        breadcrumbs: [
            {
                text: 'Kennisbank',
                disabled: false,
                to: { name: 'KnowledgeBase' },
                exact: true
            },
            {
                text: 'Opgave',
                disabled: true
            }
        ],
    }),
    computed: {
        newsForms() {
            return this.$store.state.registrationForms.newsForms;
        },
        eventsForms() {
            return this.$store.state.registrationForms.eventsForms;
        },
        sortedRegistrationForms() {
            let mergedArray = this.newsForms.concat(this.eventsForms)
            return mergedArray.sort((a, b) => {
                let aDate = a.date ? a.date : a.start_datetime;
                let bDate = b.date ? b.date : b.start_datetime;

                aDate = new Date(aDate).getTime()
                bDate = new Date(bDate).getTime()

                if (aDate > bDate) {
                    return -1
                } else {
                    return 1
                }
            })
        },
        paginatedData() {
            const start = this.pagination.pageNumber * this.pagination.itemsPerPage - this.pagination.itemsPerPage, //sets the correct start on data
                end = start + this.pagination.itemsPerPage;
            return this.sortedRegistrationForms.slice(start, end);
        },
    },
}
</script>

<style scoped>

</style>
