import apiClient from "@/api/apiClient";

// initial state
const state = {
    menuPages: [], // For index page
    pages: [], // For view page
}

// getters
const getters = {
}

// actions
const actions = {
    getMenuPages({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.menuPages.length >= 1) {
            return state.menuPages;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/page/menu')
            .then(response => {
                commit('SET_MENU_PAGES', response.data);
                return response.data;
            })
    },
    getPage({commit, state}, { slug }) {
        let pageItem = state.pages.find(page => page.slug === slug);
        if (pageItem) {
            return pageItem;
        }

        // TODO: Ook in localstorage met expiration
        return apiClient.get('/page/intranet/kennisbank/'+slug)
            .then(response => {
                commit('ADD_VIEW_PAGE', response.data);
                return response.data;
            })
    },
}

// mutations
const mutations = {
    SET_MENU_PAGES(state, menuPages) {
       state.menuPages = menuPages;
    },
    ADD_VIEW_PAGE(state, pageItem) {
        state.pages.push(pageItem);
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
