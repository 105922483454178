<template>
    <v-card>
        <v-card-title>
            <router-link :to="{ 'name': 'ImprovementList' }">
                <v-icon
                    class="mr-3"
                    color="primary"
                >
                    fal fa-thumbs-up
                </v-icon>Verbeterplannen
            </router-link>
        </v-card-title>
        <v-list two-line>
            <v-list-item v-for="improvement in improvements.slice(0, 5)" :key="improvement.number"
                         :to="{name:'ImprovementDetail', params: {number: improvement.number}}">
                <v-list-item-content>
                    <v-list-item-title>{{ improvement.title }}</v-list-item-title>
                    <v-list-item-subtitle class="primary--text">#{{ improvement.number }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon>
                        <v-icon color="grey lighten-1">fal fa-chevron-right</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "ImprovementPlans",
    mounted() {
        this.loadImprovements();
    },
    computed: {
        ...mapState({
            improvements: state => state.improvements.improvements
        })
    },
    methods: {
        loadImprovements() {
            this.isLoading = true;
            this.$store.dispatch('getImprovements')
                .finally(() => {
                    this.isLoading = false
                })
        }
    }
}
</script>

<style scoped>

</style>
