<template>
    <v-container v-if="user">
        <v-row align="center">
            <v-col cols="12" md="6">
                <h1 class="text-h1">{{ user.title }}</h1>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pt-md-4 pb-5 pb-md-3">
                <v-breadcrumbs :items="breadcrumbs" class="float-md-right pa-0"></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="8" lg="6" class="mb-5">
                <user-card :user="user"></user-card>
            </v-col>
        </v-row>
        <hr/>
        <BackButton :route="{name: 'UserList'}" />
<!--        <v-row>-->
<!--            <v-col>-->
<!--                <v-card>-->
<!--                    <pre>{{ user }}</pre>-->
<!--                </v-card>-->
<!--            </v-col>-->
<!--        </v-row>-->
    </v-container>
</template>

<script>
import BackButton from "../components/BackButton";
import UserCard from "../components/User/Card";

export default {
    name: "UserDetail",
    components: {BackButton, UserCard},
    mounted() {
        this.loadUser();
    },
    data: () => ({
        user: null,
        breadcrumbs: [
            {
                text: 'Kennisbank',
                disabled: false,
                to: {name: 'KnowledgeBase'},
                exact: true
            },
            {
                text: 'Smoelenboek',
                disabled: false,
                to: {name: 'UserList'},
                exact: true
            }
        ]
    }),
    watch: {
        '$route.params.slug'() {
            this.loadUser();
        }
    },
    methods: {
        loadUser() {
            let slug = this.$route.params.slug;
            this.$store.dispatch('getUser', {slug: slug}).then(user => {
                this.user = user;

                this.breadcrumbs.push({
                    text: user.title,
                    disabled: true
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
