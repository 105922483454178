<template>
    <v-list-group v-model="expanded" v-if="page.children.length > 0" @click="openPage" color="primary">
        <template v-slot:activator :class="{ active: isActive }">
            <v-list-item-content>
                <v-list-item-title v-text="page.title"></v-list-item-title>
            </v-list-item-content>
        </template>

        <v-list class="py-0 pl-4" v-if="page.children.length > 0">
            <NavigationItem v-for="child in page.children" :page="child" :key="child.url"></NavigationItem>
        </v-list>
    </v-list-group>
    <v-list-item v-else @click="openPage" :class="{ active: isActive }">
        <v-list-item-content>
            <v-list-item-title v-text="page.title"></v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: "NavigationItem",
    props: {
        page: Object,
    },
    data: () => ({
        expanded: false,
    }),
    computed: {
        isActive () {
            return this.page.url === this.$route.path;
        },
    },
    methods: {
        openPage () {
            if (this.page.url.substring(0, 1) !== '/') {
                window.open(this.page.url);
            } else if (this.$router.currentRoute.path !== this.page.url) {
                this.$router.push(this.page.url);
            }
        },
    },
}
</script>

<style scoped>

</style>
