<template>
    <v-container v-if="photoalbum">
        <!--        <v-row>-->
        <!--            <v-col>-->
        <!--                <v-card>-->
        <!--                    <pre>{{ photoalbum }}</pre>-->
        <!--                </v-card>-->
        <!--            </v-col>-->
        <!--        </v-row>-->
        <v-row align="center">
            <v-col cols="12" md="6">
                <h1 class="text-h1">{{ photoalbum.title }}</h1>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pt-md-4 pb-5 pb-md-3">
                <v-breadcrumbs :items="breadcrumbs" class="float-md-right pa-0"></v-breadcrumbs>
            </v-col>
        </v-row>
        <CoolLightBox
            :items="largeImages"
            :index="index"
            @close="index = null"
            :slideshow-color-bar="this.$vuetify.theme.defaults.light.primary"
            :overlay-color="'rgba(255,255,255,1)'"
        >
        </CoolLightBox>
        <v-row class="improvement-images">
            <v-col
                v-for="(image, imageIndex) in photoalbum.images"
                :key="imageIndex"
                class="d-flex child-flex"
                cols="4"
                md="3"
                lg="2"
                @click="index = imageIndex"
            >
                <div class="img">
                    <v-img
                        :src="image.thumbnail"
                        aspect-ratio="1"
                        class="grey lighten-2"
                    >
                    </v-img>
                    <div class="diamond-icon">
                        <v-icon>
                            fal fa-search
                        </v-icon>
                    </div>
                </div>
            </v-col>
        </v-row>
        <hr/>
        <BackButton :route="{name: 'PhotoalbumList'}" />
    </v-container>
</template>

<script>
import BackButton from "../components/BackButton";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
    name: "NewsDetail",
    components: {
        BackButton,
        CoolLightBox,
    },
    mounted() {
        this.loadPhotoalbum();
    },
    data: () => ({
        photoalbum: null,
        index: null,
        largeImages: [],
        breadcrumbs: [
            {
                text: 'Kennisbank',
                disabled: false,
                to: { name: 'KnowledgeBase' },
                exact: true
            },
            {
                text: 'Fotoalbums',
                disabled: false,
                to: { name: 'PhotoalbumList' },
                exact: true
            }
        ]
    }),
    watch: {
        '$route.params.slug'() {
            this.loadPhotoalbum();
        }
    },
    methods: {
        loadPhotoalbum() {
            let slug = this.$route.params.slug;
            this.$store.dispatch('getPhotoalbum', {slug: slug}).then(photoalbum => {
                this.photoalbum = photoalbum;

                this.breadcrumbs.push({
                    text: photoalbum.title,
                    disabled: true
                })

                photoalbum.images.forEach(image => {
                    this.largeImages.push(image.url);
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
