<template>
    <v-app :style="{ 'visibility': (isLoggedIn || this.$route.name == 'Login' || this.$route.name == 'ForgotPassword' || this.$route.name == 'ResetPassword') ? 'visible' : 'hidden' }">
        <NavigationDrawer v-if="isMobile" @show-dialog="onShowDialog"></NavigationDrawer>
        <Header @show-dialog="onShowDialog"></Header>

        <v-main>
            <span class="asset-5" ref="asset5" />
            <span class="left-asset" ref="leftAsset" />
            <span class="asset-3" ref="asset3" />
            <span class="asset-4" ref="asset4" />
            <span class="asset-1" ref="asset1" />
            <span class="asset-2" ref="asset2" />
            <span class="asset-6" ref="asset6" />
            <div
                class="main-content"
                :class="{ 'justify-center': (
                    this.$route.name == 'Login' ||
                    this.$route.name == 'ForgotPassword' ||
                    this.$route.name == 'ResetPassword'
                )}"
            >
                <router-view></router-view>
            </div>
        </v-main>

        <Snackbar></Snackbar>
        <v-footer v-if="!isMobile">
            <v-col
              class="text-center"
              cols="12"
            >
              v{{ appVersion }}
            </v-col>
        </v-footer>

        <v-bottom-navigation
            class="secondary"
            dark
            shift
            fixed
            app
            grow
            v-if="isMobile && (this.$route.name != 'Login' && this.$route.name != 'ForgotPassword' && this.$route.name != 'ResetPassword')"
          >

<!--             <v-btn :to="{ name: 'NewsList' }">
              <span>Nieuws</span>
              <v-icon>fal fa-fw fa-newspaper</v-icon>
            </v-btn> -->

            <v-btn :to="{ name: 'Calendar' }">
              <span>Agenda</span>
              <v-icon>fal fa-fw fa-calendar-alt</v-icon>
            </v-btn>

            <v-btn :to="{ name: 'ImprovementList' }">
              <span>Verbeterplannen</span>
              <v-icon>fal fa-fw fa-thumbs-up</v-icon>
            </v-btn>

            <v-btn :to="{ name: 'NewsList' }">
              <span>Nieuws</span>
              <v-icon>fal fa-fw fa-newspaper</v-icon>
            </v-btn>

<!--             <v-btn :to="{ name: 'KnowledgeBase' }">
              <span>Kennisbank</span>
              <v-icon>fal fa-fw fa-book</v-icon>
            </v-btn> -->
        </v-bottom-navigation>

        <v-dialog
            v-model="dialog"
            persistent
            max-width="290"
        >
            <v-card>
                <v-card-title class="justify-center">
                    <h1 class="text-sm-h2">Uitloggen</h1>
                </v-card-title>
                <v-card-text class="text-center">Weet je zeker dat je wilt uitloggen?</v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="dialog = false"
                    >
                        Annuleren
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="logout">
                        <v-icon
                            class="mr-2"
                        >
                            fal fa-ban
                        </v-icon>
                        Uitloggen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import Header from "./components/Header";
import NavigationDrawer from "./components/NavigationDrawer";
import Snackbar from "@/components/Snackbar";
import {mapState} from "vuex";

export default {
    name: 'App',

    components: {
        Snackbar,
        NavigationDrawer,
        Header,
    },

    data: () => ({
        isMobile: false,
        dialog: false,
        appVersion: process.env.VUE_APP_VERSION,
    }),

    beforeDestroy () {
        if (typeof window === 'undefined') return

        window.removeEventListener('resize', this.onResize, { passive: true })
    },
    mounted () {
        this.onResize()

        window.addEventListener('resize', this.onResize, { passive: true })
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    computed: {
        ...mapState({
            isLoggedIn: state => state.auth.isLoggedIn,
        })
    },
    methods: {
        onShowDialog () {
            this.dialog = true
        },
        logout () {
            this.$store.dispatch('logout').then(() => {
                this.dialog = false
                this.$router.push({ name: 'Login' })
            })
        },
        onResize () {
            // Because Vuetify updates the innerWidth of the screen, we use the real screen width as a breakpoint.
            let width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            this.isMobile = width < 665
        },
        handleScroll() {
            this.$refs.leftAsset.style.transform = 'translateY(' + ((-1 * document.documentElement.scrollTop) / 2) + 'px)'
            this.$refs.asset1.style.transform = 'translateY(' + ((document.documentElement.scrollTop) / 4) + 'px)'
            this.$refs.asset2.style.transform = 'translateY(' + ((document.documentElement.scrollTop) / 3) + 'px)'
            this.$refs.asset3.style.transform = 'translateY(' + ((document.documentElement.scrollTop) / 3.5) + 'px)'
            this.$refs.asset4.style.transform = 'translateY(' + ((document.documentElement.scrollTop) / 3) + 'px)'
            this.$refs.asset5.style.transform = 'translateY(' + ((document.documentElement.scrollTop) / 3.8) + 'px)'
            this.$refs.asset6.style.transform = 'translateY(' + ((document.documentElement.scrollTop) / 3) + 'px)'
        }
    }
};
</script>

<style lang="scss">
@import "assets/scss/app"
</style>
