var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('h1',{staticClass:"text-h1"},[_vm._v("Projecten")])])],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-data-table',{staticClass:"project-list-table",attrs:{"headers":_vm.headers,"loading":_vm.isLoading,"items":_vm.filteredItems,"page":_vm.page},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'ProjectDetail', params: { id: item.id }}}},[_vm._v(_vm._s(item.name))]),(item.description)?_c('div',{staticClass:"project-description",domProps:{"innerHTML":_vm._s(item.description)}}):_vm._e()]}},{key:"item.project_leads",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.project_leads),function(lead,index){return _c('UserChip',{key:index,attrs:{"user":lead}})}),1)]}},{key:"item.planners",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.planners),function(planner,index){return _c('UserChip',{key:index,attrs:{"user":planner}})}),1)]}},{key:"item.foremans",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.foremans),function(foreman,index){return _c('UserChip',{key:index,attrs:{"user":foreman}})}),1)]}},{key:"item.coordinators",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.coordinators),function(coordinator,index){return _c('UserChip',{key:index,attrs:{"user":coordinator}})}),1)]}},{key:"item.contacts",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.contacts),function(contact,index){return _c('UserChip',{key:index,attrs:{"user":contact}})}),1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }