<template>
    <v-app-bar
        app
        color="primary"
        dark
        v-if="isLoggedIn"
    >
        <div class="toolbar-logo">
            <router-link :to="{name: 'Dashboard' }">
                <v-img
                    contain
                    height="65"
                    src="../assets/images/bouwmij-janssen-logo.png"
                ></v-img>
            </router-link>
        </div>

        <v-autocomplete
            ref="searchInput"
            v-model="result"
            class="toolbar-search"
            :items="searchResults"
            :loading="isSearching"
            :search-input.sync="query"
            item-text="label"
            :item-value="getItemValue"
            @change="goToSearchResult"
            no-filter
            flat
            append-icon="fal fa-search notransform"
            :hide-no-data="isSearching || !query"
            no-data-text="Geen zoekresultaten..."
            return-object
            hide-details
            label="Zoeken..."
            solo-inverted
            v-if="!isMobile && isLoggedIn"
            v-on:keyup.enter="goToSearchPage"
            style="z-index:10;"
        >
            <template v-slot:item="{ item }">
                <v-list-item-content>
                    <v-list-item-title v-html="item.label"></v-list-item-title>
                    <v-list-item-subtitle v-if="item.group" v-html="item.group"></v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>

        <v-spacer></v-spacer>

        <v-menu
            bottom
            right
            transition="scale-transition"
            origin="top right"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-chip
                    pill
                    v-on="on"
                    v-if="user && !isMobile && isLoggedIn"
                    v-bind="attrs"
                    color="primary darken-1"
                    style="z-index:10;"
                >
                    <v-avatar left v-if="user && user.imageUrl">
                        <v-img
                            :src="user.imageUrl"
                            :alt="user.username"
                        ></v-img>
                    </v-avatar>
                    <span class="pl-2">{{ user.name }}</span>
                </v-chip>
            </template>
            <v-card width="300">
                <v-list v-if="user">
                    <v-list-item>
                        <v-list-item-avatar>
                            <v-img :src="user.imageUrl" v-if="user.imageUrl"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ user.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ user.username }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn
                                icon
                                @click="menu = false"
                            >
                                <v-icon small>fal fa-times</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <v-list>
                    <v-list-item @click="onClickProfileChangeButton">
                        <v-list-item-action>
                            <v-icon small>fal fa-user</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Profiel wijzigen</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="onClickLogoutButton">
                        <v-list-item-action>
                            <v-icon small>fal fa-sign-out</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Uitloggen</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>

        <v-icon @click="$router.push({name: 'Search'})"
                            v-if="isMobile && isLoggedIn" style="z-index:10;">fal fa-search</v-icon>
        <v-app-bar-nav-icon @click="$store.dispatch('toggleNavigationDrawer')"
                            v-if="isMobile && isLoggedIn" style="z-index:10;"></v-app-bar-nav-icon>

        <template v-slot:extension v-if="!isMobile && isLoggedIn">
            <v-tabs right hide-slider>
                <v-tab
                    v-for="item in items"
                    :key="item.title"
                    :to="{name: item.route}"
                    :exact="item.exact"
                >{{ item.title }}
                </v-tab>
            </v-tabs>
        </template>

    </v-app-bar>
</template>

<script>
import {mapState} from 'vuex';
import apiClient from "@/api/apiClient";
import routeSearcher from "../router/routeSearcher";

export default {
    name: "Header",
    data: () => ({
        isMobile: false,
        isSearching: false,
        query: '',
        searchResults: [],
        isSearchQueued: false,
        result: null,
    }),
    computed: {
        ...mapState({
            items: state => state.app.navigationItems,
            isLoggedIn: state => state.auth.isLoggedIn,
            user: state => state.auth.user
        })
    },
    beforeDestroy() {
        if (typeof window === 'undefined') return

        window.removeEventListener('resize', this.onResize, {passive: true})
    },

    mounted() {
        this.onResize()

        window.addEventListener('resize', this.onResize, {passive: true})
    },

    watch: {
        query() {
            this.search();
        },
        isSearching() {
            if (!this.isSearching && this.isSearchQueued) {
                this.search();
            }
        },
    },

    methods: {
        onClickProfileChangeButton() {
            this.$router.push({name:'Profile'});
        },
        onClickLogoutButton() {
            this.$emit('show-dialog', true)
        },
        onResize() {
            // Because Vuetify updates the innerWidth of the screen, we use the real screen width as a breakpoint.
            let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            this.isMobile = width < 665
        },
        getItemValue(data) {
            return JSON.stringify(data);
        },
        search() {
            // Handle queueing
            if (this.isSearching) {
                this.isSearchQueued = true;
                return;
            }
            this.isSearchQueued = false;
            this.searchResults = [];

            // Ignore if empty string
            if (this.query === null || this.query.trim() === '') {
                return;
            }

            this.isSearching = true;

            let searchResults = routeSearcher.getBaseSearchResultsForRouter(this.$router, this.query);
            apiClient.get('/search', { params: {
                query: this.query,
            }}).then(response => {
                if (response.status === 200) {
                    this.searchResults = searchResults.concat(response.data);
                } else {
                    console.error(response.data.error);
                    return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige zoekopdracht'});
                }
            }).catch(() => {
                return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige zoekopdracht'});
            }).finally(() => {
                this.isSearching = false;
            });
        },
        goToSearchResult() {
            routeSearcher.goToSearchResult(this.$router, this.result);
            this.searchResults = [];
        },
        goToSearchPage() {
            if (this.result) {
                this.result = null;
                return;
            }
            if (this.$route.name === 'Search') {
                this.$router.replace({name: 'Search', params: {query: this.query}});
            } else {
                this.$router.push({name: 'Search', params: {query: this.query}});
            }
            this.$refs.searchInput.blur();
        },
    },
}
</script>

<style scoped>

</style>
