<template>
    <v-card class="card-primary-border-left team-card" elevation="0">
        <v-img
            :src="user.image.url"
            :aspect-ratio="($vuetify.breakpoint.smAndDown) ? 1 : 1.4"
            class="grey lighten-2"
            v-if="user.image"
        >
        </v-img>
        <div class="content">
            <v-card-title>{{ user.title }}</v-card-title>
            <v-card-subtitle>{{ user.subtitle }}</v-card-subtitle>
            <v-card-text>
                <span class="d-block text--secondary" v-if="user.home_town">{{ user.home_town }}</span>
                <span class="d-block text--secondary" v-if="formattedAbsentDays"><strong>Afwezig op:</strong> {{ formattedAbsentDays }}</span>
                <span class="d-block text--secondary" v-if="user.birthday"><strong>Geboortedatum:</strong> {{ user.birthday|luxon('d LLLL yyyy') }}</span>
                <span class="d-block text--secondary" v-if="user.date_of_employment"><strong>In dienst sinds:</strong> {{ user.date_of_employment|luxon('d LLLL yyyy') }}</span>
                <span class="d-block text--secondary" v-if="user.intern && user.intern_period"><strong>Stageperiode:</strong> {{ user.intern_period }}</span>
                <span class="d-block text--secondary" v-if="user.intern && user.intern_project"><strong>Project/afdeling:</strong> {{ user.intern_project }}</span>
                <span class="d-block text--secondary" v-if="user.intern && user.intern_mentor"><strong>Begeleider:</strong> {{ user.intern_mentor }}</span>
                <span class="d-block text--secondary" v-if="user.intern && user.intern_education"><strong>Opleidingsgegevens:</strong> {{ user.intern_education }}</span>
                <a :href="'tel:' + user.tel_mobile" v-if="user.tel_mobile">
                    <v-icon
                        x-small
                        class="mr-1"
                    >
                        fal fa-fw fa-mobile
                    </v-icon><span>{{ user.tel_mobile }}</span>
                </a>
                <a :href="'tel:' + user.tel_office" v-if="user.tel_office">
                    <v-icon
                        x-small
                        class="mr-1"
                    >
                        fal fa-fw fa-phone-alt
                    </v-icon><span>{{ user.tel_office }}</span>
                </a>
                <a :href="'mailto:' + user.email" v-if="user.email">
                    <v-icon
                        x-small
                        class="mr-1"
                    >
                        fal fa-fw fa-envelope
                    </v-icon><span>{{ user.email }}</span>
                </a>
            </v-card-text>
    <!--                    <v-card-actions>-->
    <!--                        <v-btn class="btn-decorative" text :to="{name:'UserDetail', params: { slug: user.slug }}"-->
    <!--                               elevation="0">-->
    <!--                            Bekijken-->
    <!--                            <v-icon small class="ml-2">fal fa-long-arrow-right</v-icon>-->
    <!--                        </v-btn>-->
    <!--                    </v-card-actions>-->
        </div>
    </v-card>
</template>

<script>
export default {
    name: "Navigation",
    props: ['user'],
    computed: {
        formattedAbsentDays () {
            const orderedDays = [
                'Maandag', 'Maandagochtend', 'Maandagmiddag',
                'Dinsdag', 'Dinsdagochtend', 'Dinsdagmiddag',
                'Woensdag', 'Woensdagochtend', 'Woensdagmiddag',
                'Donderdag', 'Donderdagochtend', 'Donderdagmiddag',
                'Vrijdag', 'Vrijdagochtend', 'Vrijdagmiddag',
            ];
            const days = this.user.absent_days;
            let result = '';
            if (days) {
                orderedDays.forEach(day => {
                    if (days.includes(day)) {
                        if (result === '') {
                            result += day;
                        } else {
                            result += ', ' + day.toLowerCase();
                        }
                    }
                });
            }

            return result;
        },
    },
}
</script>

<style scoped>

</style>
