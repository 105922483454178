<template>
  <v-card>
    <v-card-title>
        <router-link :to="{ 'name': 'Calendar' }">
          <v-icon
              class="mr-3"
              color="primary"
          >
              fal fa-birthday-cake
          </v-icon>Verjaardagen / Jubilea
        </router-link>
    </v-card-title>
    <v-list two-line>
      <v-list-item
        v-for="specialDate in specialDates"
        :key="specialDate.id"
        @click="$router.push({name: 'UserDetail', params: {slug: specialDate.slug}})"
        :class="{ today: specialDate.type == 'birthday' && specialDate.isToday }"
      >
        <v-list-item-avatar tile size="50">
          <v-img aspect-ratio="1" :src="specialDate.image.url" v-if="specialDate.image"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{specialDate.title}}</v-list-item-title>
            <v-list-item-subtitle class="text-lowercase">
                {{specialDate.date|luxon('d LLLL')}}
                <span class="float-right text-primary">{{specialDate.label}}</span>
            </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
name: "Birthdays",
  mounted() {
    this.loadUsers();
  },
  data: () => ({
    users: []
  }),
  computed: {
    specialDates () {
      return this.$store.getters.getSpecialDates(10);
    }
  },
  methods: {
    loadUsers() {
      this.$store.dispatch('getUsers').then(users => {
        this.users = users
      });
    }
  }
}
</script>

<style scoped>

</style>
