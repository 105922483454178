<template>
    <v-container v-if="event">
<!--        <v-row>-->
<!--            <v-col>-->
<!--                <v-card>-->
<!--                    <pre>{{ event }}</pre>-->
<!--                </v-card>-->
<!--            </v-col>-->
<!--        </v-row>-->
        <span class="text--disabled">
            {{ event.start_datetime|luxon('dd-MM-yyyy HH:mm') }}
            <span v-if="event.start_datetime != event.end_datetime && event.end_datetime">t/m {{ event.end_datetime|luxon('dd-MM-yyyy HH:mm') }}</span>
        </span>
        <h1 class="text-h1 mb-3">{{ event.title }}</h1>
        <v-btn class="btn-decorative" v-if="event" :href="icsHref" :download="event.title+'.ics'" target="_blank" elevation="0">
            <v-icon small class="mr-2">
                fal fa-calendar-plus
            </v-icon>
            Toevoegen aan agenda
        </v-btn>
        <SignupForm v-if="event.form_configuration" :multiple-entries="event.formMultipleEntries" :entries="event.form_entries" :configuration="event.form_configuration" :expiration-date="event.expirationDate" :show-entries="event.showFormEntries"  :submission="event.form_submission" content-type="portal_event" :id="event.form_id" :is-anonymous="event.anonymousForm"></SignupForm>
        <hr class="mt-5 mb-4" />
        <BackButton :route="{name: 'Calendar'}" />
    </v-container>
</template>

<script>
const ics = require('ics');
import BackButton from "../components/BackButton";
import { DateTime } from "luxon";
import SignupForm from "../components/SignupForm";

export default {
    name: "EventDetail",
    components: {
        BackButton,
        SignupForm,
    },
    mounted() {
        this.loadEvent();
    },
    data: () => ({
        event: null,
        icsFile: null,
    }),
    watch: {
        '$route.params.slug'() {
            this.loadEvent();
        }
    },
    computed: {
        icsHref () {
            let endDatetime = this.event.end_datetime ? this.event.end_datetime : this.event.start_datetime;
            return ics.createEvent({
                title: this.event.title,
                description: this.event.title,
                start: DateTime.fromISO(this.event.start_datetime).toFormat('yyyy-M-d-H-m').split('-'),
                end: DateTime.fromISO(endDatetime).toFormat('yyyy-M-d-H-m').split('-'),
            }, (error, value) => {
                if (error) {
                    console.log(error)
                }

                let data = new File([value], {type: "text/calendar"});

                // If we are replacing a previously generated file we need to
                // manually revoke the object URL to avoid memory leaks.
                if (this.icsFile !== null) {
                    window.URL.revokeObjectURL(this.icsFile);
                }

                this.icsFile = window.URL.createObjectURL(data);

                return this.icsFile;
            });
        },
    },
    methods: {
        loadEvent() {
            let slug = this.$route.params.slug;
            this.$store.dispatch('getEvent', {slug: slug}).then(event => {
                this.event = event;
            })
        }
    }
}
</script>

<style scoped>

</style>
