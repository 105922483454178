<template>
  <v-container fluid class="dashboard">
    <v-row>
      <v-col cols="12" sm="6" lg="3">
        <Birthdays></Birthdays>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <News></News>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <ImprovementPlans></ImprovementPlans>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <Agenda class="mb-6"></Agenda>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

import Birthdays from "../components/Dashboard/Birthdays";
import News from "../components/Dashboard/News";
import ImprovementPlans from "../components/Dashboard/ImprovementPlans";
import Agenda from "../components/Dashboard/Agenda";
export default {
  name: 'Dashboard',
  components: {
    Agenda,
    ImprovementPlans,
    News,
    Birthdays
  },
  data: () => ({

  })
}
</script>
