import apiClient from "@/api/apiClient";

// initial state
const state = {
    photoalbums: [], // For index page
    viewPhotoalbum: [], // For view page
}

// getters
const getters = {
}

// actions
const actions = {
    getPhotoalbums({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.photoalbums.length >= 1) {
            return state.photoalbums;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/photoalbum')
            .then(response => {
                commit('SET_PHOTOALBUMS', response.data);
                return response.data;
            })
    },
    getPhotoalbum({commit, state}, { slug }) {
        let photoalbum = state.viewPhotoalbum.find(photoalbum => photoalbum.slug === slug);
        if (photoalbum) {
            return photoalbum;
        }

        // TODO: Ook in localstorage met expiration
        return apiClient.get('/photoalbum/album/'+slug)
            .then(response => {
                commit('ADD_VIEW_PHOTOALBUM', response.data);
                return response.data;
            })
    },
}

// mutations
const mutations = {
    SET_PHOTOALBUMS(state, photoalbums) {
       state.photoalbums = photoalbums;
    },
    ADD_VIEW_PHOTOALBUM(state, photoalbum) {
        state.viewPhotoalbum.push(photoalbum);
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
