import apiClient from "@/api/apiClient";

// initial state
const state = {
    newsItems: [], // For index page
    viewNewsItems: [], // For view page
}

// getters
const getters = {
}

// actions
const actions = {
    getNewsItems({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.newsItems.length >= 1) {
            return state.newsItems;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/news')
            .then(response => {
                commit('SET_NEWS_ITEMS', response.data);
                return response.data;
            })
    },
    getNewsItem({commit, state}, { slug }) {
        let newsItem = state.viewNewsItems.find(newsItem => newsItem.slug === slug);
        if (newsItem) {
            return newsItem;
        }

        // TODO: Ook in localstorage met expiration
        return apiClient.get('/news/intranet/nieuws/'+slug)
            .then(response => {
                commit('ADD_VIEW_NEWS_ITEM', response.data);
                return response.data;
            })
    },
}

// mutations
const mutations = {
    SET_NEWS_ITEMS(state, newsItems) {
       state.newsItems = newsItems;
    },
    ADD_VIEW_NEWS_ITEM(state, newsItem) {
        state.viewNewsItems.push(newsItem);
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
