<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-row class="login-form elevation-12">
                <v-col sm="5" class="primary intro d-none d-sm-flex">
                    <h1>Welkom</h1>
                    <p class="px-2 mb-4">Vul uw Intranet Bouwmij-Janssen-gebruikersnaam en het wachtwoord dat bij uw gebruikersnaam hoort in om in te loggen.</p>
                    <v-btn outlined color="secondary" small :to="{name: 'ForgotPassword'}">Nieuw wachtwoord aanvragen</v-btn>
                </v-col>
                <v-col sm="7" class="pa-0">
                    <v-form ref="form" @submit.prevent="checkLogin">
                        <v-card class="elevation-0">
                            <v-card-text>
                                <div class="animated-logo mx-3">
                                    <lottie-animation
                                        path="logo.json"
                                        :loop="false"
                                    />
<!--                                    <v-img-->
<!--                                        contain-->
<!--                                        height="120"-->
<!--                                        src="../assets/images/bouwmij-janssen-logo-slogan.svg"-->
<!--                                    ></v-img>-->
                                </div>
                                <v-alert type="error" :value="error">{{ error }}</v-alert>
                                <v-text-field
                                    type="text"
                                    prepend-icon="far fa-user"
                                    v-model="username"
                                    label="Gebruikersnaam"
                                    :rules="usernameRules"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    type="password"
                                    prepend-icon="far fa-key"
                                    v-model="password"
                                    label="Wachtwoord"
                                    :rules="passwordRules"
                                    required
                                ></v-text-field>
                                <v-checkbox
                                    v-model="rememberMe"
                                    label="Onthoud mij"
                                ></v-checkbox>
                            </v-card-text>
                            <v-card-actions class="justify-center pb-3">
                                <v-btn large type="submit" color="primary">
                                    <v-icon
                                        small
                                        class="mr-2"
                                      >
                                        fal fa-sign-in
                                    </v-icon>
                                    Inloggen
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-col>
            </v-row>
        </v-layout>
    </v-container>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import {mapState} from "vuex";

export default {
    name: "Login",
    data: () => ({
        username: '',
        usernameRules: [
            v => !!v || 'Verplicht'
        ],
        password: '',
        passwordRules: [
            v => !!v || 'Verplicht'
        ],
        rememberMe: false,
        error: null
    }),
    components: {
        LottieAnimation
    },
    computed: {
        ...mapState({
            loginRedirectRoute: state => state.app.loginRedirectRoute,
        })
    },
    mounted() {
        if (this.$store.state.auth.isLoggedIn) {
            this.$router.push({name: "Dashboard"})
        }
    },
    methods: {
        checkLogin() {
            // Check if form is valid
            if (this.$refs.form.validate()) {

                // Submit data to auth store
                this.$store.dispatch('login', {
                    username: this.username,
                    password: this.password,
                    _remember_me: this.rememberMe,
                }).then((success) => {
                    if (success) {
                        if (this.loginRedirectRoute) {
                            this.$router.push(this.loginRedirectRoute);
                        } else {
                            this.$router.push({name: "Dashboard"})
                        }
                    } else {
                        return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige gegevens'});
                    }
                }).catch((e) => {
                    console.error(e);
                    return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Serverfout'});
                })
            }
        }
    }

}
</script>

<style scoped>

</style>
