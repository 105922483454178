<template>
    <v-container>
        <v-row class="justify-center">
            <v-col sm="5" class="pa-0">
            <h1 class="text-h1 mb-5 text-center">Wachtwoord wijzigen</h1>
                <v-card
                    elevation="2"
                >
                    <v-form ref="form" @submit.prevent="changePassword">
                        <v-card class="elevation-0">
                            <v-card-text>
                                <p class="px-2 mb-4">Vul een nieuw wachtwoord in voor uw Intranet Bouwmij-Janssen-account.</p>
                                <v-alert type="error" :value="error">{{ error }}</v-alert>
                                <v-text-field
                                    type="password"
                                    prepend-icon="far fa-key"
                                    v-model="password"
                                    label="Nieuw wachtwoord"
                                    :rules="passwordRules"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    type="password"
                                    prepend-icon="far fa-key"
                                    v-model="repeatPassword"
                                    label="Herhaal wachtwoord"
                                    :rules="repeatPasswordRules"
                                    required
                                ></v-text-field>
                            </v-card-text>
                            <v-card-actions class="justify-center pb-3">
                                <v-btn class="btn-decorative" type="submit" elevation="0">
                                    <v-icon small class="mr-2">fal fa-sign-in</v-icon>
                                    Wachtwoord wijzigen
                                </v-btn>
                            </v-card-actions>
                            <v-card-actions class="justify-center pb-3">
                                <a @click="backToProfile">Terug naar profiel</a>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "ChangePassword",
    data: () => ({
        password: '',
        repeatPassword: '',
        passwordRules: [
            v => !!v || 'Verplicht',
            v => v.length >= 6 || 'Minimaal 6 karakters',
        ],
        error: null,
        isSubmitted: false,
    }),
    computed: {
        repeatPasswordRules () {
            return [
                v => !!v || 'Verplicht',
                v => v === this.password || 'Wachtwoorden komen niet overeen',
                v => v.length >= 6 || 'Minimaal 6 karakters',
            ];
        }
    },
    methods: {
        changePassword() {
            // Check if form is valid
            if (!this.isSubmitted && this.$refs.form.validate()) {
                this.isSubmitted = true;
                // Submit data to auth store
                this.$store.dispatch('updatePassword', {
                    password: this.password,
                }).then((success) => {
                    if (success) {
                        this.$store.dispatch('showSnackbar', {color: 'success', text: 'Wachtwoord gewijzigd'});
                        this.$store.dispatch('logout').then(() => {
                            this.$router.push({ name: 'Login' });
                        })
                    } else {
                        this.isSubmitted = false;
                        return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige gegevens'});
                    }
                }).catch((e) => {
                    this.isSubmitted = false;
                    console.error(e);
                    return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Serverfout'});
                })
            }
        },
        backToProfile() {
            this.$router.push({name:'Profile'});
        },
    }
}
</script>

<style scoped>

</style>
