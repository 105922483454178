<template>
    <v-container v-if="improvement">
        <v-row>
            <v-col>
                <h1 class="text-h1">Verbeterplan #{{ improvement.number }}
                    <v-chip class="float-right" :color="improvement.ready ? 'success' : 'error'">
                        {{ improvement.ready ? 'Gereed' : 'Niet gereed' }}
                    </v-chip>
                </h1>
                <h2 class="text-h2">
                    {{ improvement.title }}
                    <v-btn v-if="improvement.isAllowedToEdit" class="float-right" small color="primary" @click="$router.push({name: 'ImprovementEdit', params: {number: improvement.number}})">Bewerken</v-btn>
                </h2>
            </v-col>
        </v-row>

        <hr />

        <v-row class="align-center">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Volgnr:</strong>
            </v-col>
            <v-col class="pt-0 pb-0">
                {{ improvement.number }}
            </v-col>
        </v-row>

        <v-row class="align-center" v-if="improvement.createdBy">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Ingediend door:</strong>
            </v-col>
            <v-col class="pt-0 pb-0">
                {{ improvement.createdBy.title }}
            </v-col>
        </v-row>

        <v-row class="align-center" v-if="improvement.reportedByDepartment">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Ingediend door afdeling:</strong>
            </v-col>
            <v-col class="pt-0 pb-0">
                {{ improvement.reportedByDepartment }}
            </v-col>
        </v-row>

        <v-row v-if="improvement.project || improvement.projectNo" class="align-center">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Projectnummer:</strong>
            </v-col>
            <v-col class="pt-0 pb-0">
                {{ improvement.project ? improvement.project.project_no : improvement.projectNo }}
            </v-col>
        </v-row>

        <v-row v-if="improvement.type" class="align-center">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Type:</strong>
            </v-col>
            <v-col class="pt-0 pb-0">
                {{ improvement.type }}
            </v-col>
        </v-row>

        <v-row class="align-center">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Datum melding:</strong>
            </v-col>
            <v-col class="pt-0 pb-0">
                {{ improvement.date|luxon('dd-MM-yyyy') }}
            </v-col>
        </v-row>

        <v-row class="align-center">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Terugkop indiener:</strong>
            </v-col>
            <v-col class="pt-0 pb-0">
                {{ improvement.terugkopIndiener|luxon('dd-MM-yyyy') }}
            </v-col>
        </v-row>

        <v-row class="align-center" v-if="improvement.department">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Ondergebracht bij afdeling:</strong>
            </v-col>
            <v-col class="pt-0 pb-0">
                {{ improvement.department }}
            </v-col>
        </v-row>

        <v-row class="align-center" v-if="improvement.assignedUser">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Ondergebracht bij persoon:</strong>
            </v-col>
            <v-col class="pt-0 pb-0">
                {{ improvement.assignedUser.title }}
            </v-col>
        </v-row>

        <v-row class="align-center">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Gereed:</strong>
            </v-col>
            <v-col class="pt-0 pb-0 d-flex">
                {{ improvement.ready ? 'Ja' : 'Nee' }}
                <div v-if="'support@forwart.nl' === user.username || 'pre@bouwmij-janssen.nl' === user.username" class="mb-2">
                    <v-btn v-if="improvement.ready && improvement.progress === 100" class="ml-3" small color="danger" @click="saveReady(false)">Markeer als niet gereed</v-btn>
                    <v-btn v-if="!improvement.ready && improvement.progress === 100" class="ml-3" small color="primary" @click="saveReady(true)">Markeer gereed</v-btn>
                </div>
            </v-col>
        </v-row>

        <v-row class="align-center">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Voortgang:</strong>
            </v-col>
            <v-col class="pt-0 pb-0" v-if="!isEditingProgress">
                {{ improvement.progress }} %
                <v-btn class="ml-3" small color="primary" @click="editProgress">Bewerken</v-btn>
                <v-btn class="ml-2" small @click="viewProgressHistory = !viewProgressHistory">Geschiedenis</v-btn>
            </v-col>
            <v-col class="pt-0 pb-0" v-else>
                <v-slider :disabled="isUpdatingProgress" v-model="newProgress" min="0" max="100" thumb-label="always" style="margin-top: 40px;"></v-slider>
                <v-btn class="mt-n5" :disabled="newProgress === improvement.progress || isUpdatingProgress" :loading="isUpdatingProgress" small color="primary" @click="saveProgress">Opslaan</v-btn>
                <v-btn class="mt-n5 ml-2" small :disabled="isUpdatingProgress" @click="isEditingProgress = false">Annuleren</v-btn>
            </v-col>
        </v-row>

        <v-row v-if="viewProgressHistory" class="align-center">
            <v-col class="pt-0 pb-0">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">Voortgang</th>
                            <th class="text-left">Medewerker</th>
                            <th class="text-left">Datum</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(update, index) in improvement.progressUpdates" :key="index">
                            <td>{{ update.progress }} %</td>
                            <td>{{ update.user.title }}</td>
                            <td>{{ update.createdAt|luxon('dd-MM-yyyy HH:mm') }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>

        <v-row v-if="improvement.endDate" class="align-center">
            <v-col cols="12" md="3" class="pt-0 pb-0">
                <strong>Einddatum:</strong>
            </v-col>
            <v-col class="pt-0 pb-0">
                {{ improvement.endDate|luxon('dd-MM-yyyy') }}
            </v-col>
        </v-row>

        <br />

        <v-row class="align-center">
            <v-col cols="12">
                <h3>1. Wat is het probleem?</h3>
                <p style="white-space: break-spaces;">{{improvement.problem}}</p>
            </v-col>
            <v-col cols="12">
                <h3>2. Wat is het doel?</h3>
                <p style="white-space: break-spaces;">{{improvement.purpose}}</p>
            </v-col>
            <v-col cols="12">
                <h3>3. Wat is de oplossing?</h3>
                <p style="white-space: break-spaces;">{{improvement.solution}}</p>
            </v-col>
            <v-col cols="12">
                <h3>4. Hoe gaan we dit borgen?</h3>
                <p style="white-space: break-spaces;">{{improvement.secure}}</p>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="8" lg="6">
                <v-expansion-panels flat>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="pl-0"><h3>Opmerkingen <v-chip color="primary" class="ml-2">{{ improvement.comments.length }}</v-chip></h3></v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-textarea v-model="comment" label="Nieuwe opmerking" outlined></v-textarea>
                            <v-btn
                                class="btn-decorative mt-n5 mb-5"
                                :disabled="!comment || isSubmittingComment"
                                :loading="isSubmittingComment"
                                @click="submitComment"
                                elevation="0"
                            >
                                <v-icon
                                    small
                                    class="mr-2"
                                >
                                    fal fa-arrow-right
                                </v-icon>
                                Opmerking plaatsen
                            </v-btn>
                            <v-card v-for="(comment, index) in improvement.comments" :key="index" elevation="1" class="mb-4">
                                <v-card-title>
                                    <v-avatar left v-if="comment.user.image">
                                        <v-img :src="comment.user.image.url"></v-img>
                                    </v-avatar>
                                    <v-icon left v-else style="width: 48px;" class="mr-0">
                                        fal fa-user
                                    </v-icon>
                                    <span class="text-h7">{{comment.user.title}}</span>
                                </v-card-title>

                                <v-card-text>{{ comment.comment }}</v-card-text>

                                <v-card-actions>
                                    <v-row
                                        align="center"
                                        justify="end"
                                    >
                                        <span class="mr-2 grey--text">{{ comment.createdAt|luxon('dd-MM-yyyy HH:mm') }}</span>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>

        <v-row class="align-center" v-if="improvement.remarks">
            <v-col cols="12">
                <h3>Extra opmerkingen</h3>
                <p style="white-space: break-spaces;">{{improvement.remarks}}</p>
            </v-col>
        </v-row>

        <v-row class="improvement-images">
            <v-col cols="12" v-if="improvement.image">
                <div class="img" style="max-width:500px;"
                     @click="imageIndex = 0"
                >
                    <v-img
                        :src="improvement.image.thumbnail"
                        :alt="improvement.title"
                        max-width="500"
                        max-height="500"
                    ></v-img>
                    <div class="diamond-icon">
                        <v-icon>
                            fal fa-search
                        </v-icon>
                    </div>
                </div>
            </v-col>
            <v-col
                v-for="(extraImage, index) in improvement.extraImages"
                :key="index"
                class="d-flex child-flex"
                cols="4"
                md="3"
                lg="2"
                @click="imageIndex = index + (improvement.image ? 1 : 0)"
            >
                <div class="img">
                    <v-img
                        :src="extraImage.thumbnail"
                        aspect-ratio="1"
                        class="grey lighten-2"
                    ></v-img>
                    <div class="diamond-icon">
                        <v-icon>
                            fal fa-search
                        </v-icon>
                    </div>
                </div>
            </v-col>
        </v-row>

        <CoolLightBox
            :items="largeImages"
            :index="imageIndex"
            @close="imageIndex = null"
            :slideshow-color-bar="this.$vuetify.theme.defaults.light.primary"
            :overlay-color="'rgba(255,255,255,1)'"
        >
        </CoolLightBox>

        <v-row v-if="improvement.attachment" class="mt-5">
            <v-col class="pb-0">
                <div class="fileAsset">
                    <a target="_blank" :href="improvement.attachment.url">{{improvement.attachment.name}}</a>
                </div>
            </v-col>
        </v-row>

        <v-row v-if="improvement.extraFiles.length > 0" class="mt-n3 mb-n5">
            <v-col>
                <div class="fileAsset" v-for="extraFile in improvement.extraFiles" :key="extraFile.url">
                    <a target="_blank" :href="extraFile.url">{{extraFile.name}}</a>
                </div>
            </v-col>
        </v-row>

        <hr />

        <BackButton :route="{name: 'ImprovementList'}" />
    </v-container>
</template>

<script>
import BackButton from "../components/BackButton";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import {mapState} from "vuex";

export default {
    name: "ImprovementDetail",
    components: {
        BackButton,
        CoolLightBox,
    },
    mounted () {
        this.loadImprovement();
    },
    data: () => ({
        improvement: null,
        imageIndex: null,
        comment: null,
        isSubmittingComment: false,
        isEditingProgress: false,
        newProgress: 0,
        viewProgressHistory: false,
        isUpdatingProgress: false,
    }),
    watch: {
        '$route.params.number' () {
            this.loadImprovement();
        }
    },
    computed: {
        largeImages () {
            let images = [];

            if (this.improvement && this.improvement.image) {
                images.push(this.improvement.image.url);
            }
            if (this.improvement && this.improvement.extraImages) {
                this.improvement.extraImages.forEach(extraImage => {
                    images.push(extraImage.url);
                });
            }

            return images;
        },
        ...mapState({
            user: state => state.auth.user
        })
    },
    methods: {
        loadImprovement() {
            let number = this.$route.params.number;
            this.$store.dispatch('getImprovementDetail', { number: number }).then(improvement => {
                this.improvement = improvement;
            })
        },
        submitComment () {
            if (!this.comment) {
                return;
            }

            this.isSubmittingComment = true;

            this.$store.dispatch('addImprovementComment', { improvement: this.improvement, comment: this.comment }).then(improvement => {
                this.improvement = improvement;
                this.comment = null;
                this.isSubmittingComment = false;
            });
        },
        editProgress () {
            this.newProgress = this.improvement.progress;
            this.isEditingProgress = true;
        },
        saveProgress () {
            if (this.newProgress === this.improvement.progress) {
                return;
            }

            this.isUpdatingProgress = true;

            this.$store.dispatch('updateProgress', { improvement: this.improvement, progress: this.newProgress }).then(improvement => {
                this.improvement = improvement;
                this.isEditingProgress = false;
                this.isUpdatingProgress = false;
            });
        },
        saveReady(ready) {
            this.isUpdatingProgress = true;

            this.$store.dispatch('updateReady', {improvement: this.improvement, ready: ready}).then(improvement => {
                this.improvement = improvement;
                this.isUpdatingProgress = false;
            })
        },
    }
}
</script>

<style scoped>

</style>
