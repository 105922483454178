<template>
    <v-card>
        <v-card-title>
            <router-link :to="{ 'name': 'NewsList' }">
                <v-icon
                    class="mr-3"
                    color="primary"
                >
                    fal fa-newspaper
                </v-icon>Nieuws
            </router-link>
        </v-card-title>
        <v-list two-line>
            <v-list-item v-for="newsItem in newsItems.slice(0, 5)" :key="newsItem.id"
                         :to="{name:'NewsDetail', params: {slug: newsItem.slug}}">
                <v-list-item-avatar tile size="60">
                    <v-img v-if="newsItem.image" :src="newsItem.image.thumbnail"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        <span class="d-block text--secondary">{{ newsItem.date|luxon('dd-MM-yyyy') }}</span>
                        {{ newsItem.title }}
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon>
                        <v-icon color="grey lighten-1">fal fa-chevron-right</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>

export default {
    name: "News",
    mounted() {
        this.loadNewsItems();
    },
    computed: {
        newsItems() {
            return this.$store.state.news.newsItems;
        }
    },
    methods: {
        loadNewsItems() {
            this.$store.dispatch('getNewsItems')
        }
    }
}
</script>

<style scoped>

</style>
