<template>
    <v-container>
        <v-row>
            <v-col>
                <h1 class="text-h1 mb-3">Wijziging persoonlijke gegevens</h1>
                <p>Dit formulier is bestemd voor het doorgeven van gewijzigde persoonlijke gegevens.<br/>
                    Het is de bedoeling dat enkel de gegevens die daadwerkelijk veranderd zijn, worden doorgegeven.</p>
            </v-col>
        </v-row>

        <v-form
            ref="form"
            v-model="isValid"
            class="pb-3"
        >
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="name"
                        label="Naam"
                        required
                        :rules="requiredRules"
                        :disabled="isSubmitting"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        v-model="firstName"
                        label="Voornaam"
                        required
                        :rules="requiredRules"
                        :disabled="isSubmitting"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        v-model="address"
                        label="Adres"
                        :disabled="isSubmitting"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        v-model="postalCodeCity"
                        label="Postcode en woonplaats"
                        :disabled="isSubmitting"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        v-model="phoneNumber"
                        label="Telefoonnummer (vast)"
                        :disabled="isSubmitting"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        v-model="mobileNumber"
                        label="Telefoonnummer (mobiel)"
                        :disabled="isSubmitting"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        v-model="email"
                        label="E-mailadres (privé)"
                        :disabled="isSubmitting"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        v-model="maritalStatus"
                        label="Burgerlijke staat"
                        :disabled="isSubmitting"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        v-model="partnerName"
                        label="Naam partner"
                        :disabled="isSubmitting"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        v-model="doctorName"
                        label="Naam huisarts"
                        :disabled="isSubmitting"
                        outlined
                    ></v-text-field>
                    <v-textarea
                        label="Diversen/toelichting"
                        v-model="description"
                        :disabled="isSubmitting"
                        outlined
                    ></v-textarea>
                    <v-btn
                        class="btn-decorative"
                        :disabled="!isValid || isSubmitting"
                        :loading="isSubmitting"
                        @click="submit"
                        elevation="0"
                    >
                        <v-icon
                            small
                            class="mr-2"
                        >
                            fal fa-arrow-right
                        </v-icon>
                        Indienen
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <hr />
        <BackButton :route="{name: 'Dashboard'}" />
    </v-container>
</template>

<script>
import BackButton from "../components/BackButton";

export default {
    name: "PersonalInfoSubmit",
    components: {
        BackButton,
    },
    data: () => ({
        isValid: false,
        isSubmitting: false,
        name: null,
        firstName: null,
        address: null,
        postalCodeCity: null,
        phoneNumber: null,
        mobileNumber: null,
        email: null,
        maritalStatus: null,
        partnerName: null,
        doctorName: null,
        description: null,
        requiredRules: [
            v => !!v || 'Dit veld is verplicht',
        ],
    }),
    methods: {
        submit() {
            if (!this.$refs.form.validate()) {
                return;
            }

            let formData = new FormData();
            formData.set('name', this.name);
            formData.set('firstName', this.firstName);
            formData.set('address', this.address);
            formData.set('postalCodeCity', this.postalCodeCity);
            formData.set('phoneNumber', this.phoneNumber);
            formData.set('mobileNumber', this.mobileNumber);
            formData.set('email', this.email);
            formData.set('maritalStatus', this.maritalStatus);
            formData.set('partnerName', this.partnerName);
            formData.set('doctorName', this.doctorName);
            formData.set('description', this.description);

            this.isSubmitting = true;

            this.$store.dispatch('updatePersonalInfo', formData).then(() => {
                this.isSubmitting = false;
                this.name = null;
                this.firstName = null;
                this.address = null;
                this.postalCodeCity = null;
                this.phoneNumber = null;
                this.mobileNumber = null;
                this.email = null;
                this.maritalStatus = null;
                this.partnerName = null;
                this.doctorName = null;
                this.description = null;
                this.$store.dispatch('showSnackbar', {color: 'success', text: 'Wijziging ingediend'});
                this.$router.push({name: 'Dashboard'});
            });
        },
    },
}
</script>

<style scoped>

</style>
