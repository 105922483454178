<template>
    <div class="mt-5">
        <h2 v-if="showTitle && !isExpired && (submission === null || multipleEntries) && !isSubmitted">{{formTitle}}</h2>
        <h3 class="mt-1 mb-2" v-if="showTitle && (submission === null || multipleEntries) && !isSuccess && !isExpired">{{ formSubtitle }}</h3>
        <v-form ref="form" @submit.prevent="submit" v-if="isInitialized && (submission === null || multipleEntries) && !isSuccess && !isExpired" class="signup-form pb-4">
            <div v-for="field in configuration" :key="field.name">
                <div
                    v-if="field.type === 'ForwartCms\\FormBuilderBundle\\Element\\Type\\ParagraphType'"
                    v-html="field.options.data"
                    class="mt-5 pt-5 mb-3"
                ></div>
                <v-text-field
                    v-if="field.type === 'ForwartCms\\FormBuilderBundle\\Element\\Type\\TextType'"
                    v-model="data[field.name]"
                    :label="field.options.label"
                    :required="field.options.required === true"
                    :placeholder="field.options.attr ? field.options.attr.placeholder : null"
                    :rules="field.options.required ? requiredRules : []"
                    outlined
                ></v-text-field>
                <v-text-field
                    v-if="field.type === 'ForwartCms\\FormBuilderBundle\\Element\\Type\\NumberType'
                        && !(field.options.attr.min && field.options.attr.min >= 0
                        && field.options.attr.max && field.options.attr.max <= 10)"
                    type="number"
                    v-model="data[field.name]"
                    :min="field.options.attr.min"
                    :max="field.options.attr.max"
                    :placeholder="field.options.attr.placeholder"
                    :label="field.options.label"
                    :required="field.options.required === true"
                    :rules="field.options.required ? requiredRules : []"
                    outlined
                ></v-text-field>
                <div v-if="field.type === 'ForwartCms\\FormBuilderBundle\\Element\\Type\\NumberType'
                        && field.options.attr.min && field.options.attr.min >= 0
                        && field.options.attr.max && field.options.attr.max <= 10">
                    <span>{{ field.options.label }}</span>
                    <v-slider
                        v-model="data[field.name]"
                        :min="field.options.attr.min"
                        :max="field.options.attr.max"
                        :required="field.options.required === true"
                        :rules="field.options.required ? requiredRules : []"
                        :tick-labels="getNumberTickLabels(field)"
                        class="mb-5"
                    ></v-slider>
                </div>
                <v-textarea
                    v-if="field.type === 'ForwartCms\\FormBuilderBundle\\Element\\Type\\TextareaType'"
                    v-model="data[field.name]"
                    :label="field.options.label"
                    :placeholder="field.options.attr ? field.options.attr.placeholder : null"
                    :required="field.options.required === true"
                    :rules="field.options.required ? requiredRules : []"
                    outlined
                ></v-textarea>
                <v-checkbox
                    v-if="field.type === 'ForwartCms\\FormBuilderBundle\\Element\\Type\\CheckboxType' || field.type === 'App\\FormBuilder\\Type\\PortalCheckboxType'"
                    v-model="data[field.name]"
                    :label="field.options.label"
                    :required="field.options.required === true"
                    :rules="field.options.required ? requiredRules : []"
                    outlined
                ></v-checkbox>
                <v-select
                    v-if="field.type === 'ForwartCms\\FormBuilderBundle\\Element\\Type\\ChoiceType' || field.type === 'App\\FormBuilder\\Type\\PortalChoiceType'"
                    v-model="data[field.name]"
                    :label="field.options.label"
                    :required="field.options.required === true"
                    :rules="field.options.required ? requiredRules : []"
                    :items="Object.values(field.options.choices)"
                    :multiple="field.options.multiple === true"
                    item-value="id"
                    item-label="label"
                    outlined
                ></v-select>
            </div>
            <v-btn class="btn-decorative" type="submit" elevation="0">
                <v-icon small class="mr-2">fal fa-arrow-right</v-icon>
                Verzenden
            </v-btn>
        </v-form>
        <v-alert class="mt-3" v-if="(submission !== null && !multipleEntries) || isSuccess" type="success">
            <span>{{ formThanks }}</span>
        </v-alert>
        <hr v-if="showEntriesTable" />
        <h2 v-if="showEntriesTable">Aanmeldingen</h2>
        <v-data-table
            v-if="showEntriesTable"
            :headers="entryHeaders"
            :items="entryRows"
            :items-per-page="10"
            class="elevation-1 mb-5"
        ></v-data-table>
    </div>
</template>

<script>
import apiClient from "../api/apiClient";

export default {
    name: "SignupForm",
    props: {
        entries: {
            required: true
        },
        configuration: {
            type: Array,
            required: true
        },
        multipleEntries: {
            type: Boolean,
            required: false,
        },
        submission: {
            type: String,
            required: false
        },
        contentType: {
            type: String,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        expirationDate: {
            type: String,
            required: false
        },
        isAnonymous: {
            type: Boolean,
            required: true
        },
        showEntries: {
            type: Boolean,
            required: false
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        formTitle: {
            type: String
        },
        formSubtitle: {
            type: String
        },
        formThanks: {
            type: String
        }
    },
    data: () => ({
        data: {},
        isSubmitted: false,
        requiredRules: [
            v => !!v || 'Verplicht'
        ],
        isInitialized: false,
        isSuccess: false,
    }),
    mounted() {
        // Initialize all fields as they are dynamic.
        this.configuration.forEach(field => {
            if (field.type === 'ForwartCms\\FormBuilderBundle\\Element\\Type\\CheckboxType' || field.type === 'App\\FormBuilder\\Type\\PortalCheckboxType') {
                this.data[field.name] = false;
            } else if ((field.type === 'ForwartCms\\FormBuilderBundle\\Element\\Type\\ChoiceType' || field.type === 'App\\FormBuilder\\Type\\PortalChoiceType') && field.options.multiple === true) {
                this.data[field.name] = [];
            } else {
                this.data[field.name] = '';
            }
        });
        this.isInitialized = true;
    },
    computed: {
        entryHeaders() {
            let headers = [
                { text: 'Naam', value: 'employee' },
            ];

            this.configuration.forEach(field => {
                if (field.options.show_results) {
                    headers.push({text: field.options.label, value: field.name});
                }
            });

            return headers;
        },
        entryRows() {
            let rows = [];

            this.entries.forEach(entry => {
                let row = {
                    employee: entry.employee,
                };

                this.configuration.forEach(field => {
                    let value = entry.data[field.name];
                    if (value === true) {
                        value = 'Ja';
                    } else if (value === false) {
                        value = 'Nee';
                    }
                    row[field.name] = value;
                });

                rows.push(row);
            });

            return rows;
        },
        showEntriesTable() {
            return this.showEntries && !this.isAnonymous;
        },
        isExpired() {
            let date = new Date().getTime()
            let expDate = new Date(this.expirationDate).getTime()
            if (!expDate) {
                return
            }

            return !(expDate === 0 || date < expDate);
        },
    },
    methods: {
        getNumberTickLabels(field) {
            let labels = [];

            for (let value = field.options.attr.min; value <= field.options.attr.max; value++) {
                labels.push(value);
            }

            return labels;
        },
        submit() {
            if (!this.isSubmitted && this.$refs.form.validate()) {
                this.isSubmitted = true;
                let formData = new FormData();
                this.configuration.forEach(field => {
                    if ((field.type === 'ForwartCms\\FormBuilderBundle\\Element\\Type\\ChoiceType' || field.type === 'App\\FormBuilder\\Type\\PortalChoiceType') && field.options.multiple === true) {
                        this.data[field.name].forEach(value => {
                            formData.append('form[' + field.name + '][]', value);
                        });
                    } else if ((field.type !== 'ForwartCms\\FormBuilderBundle\\Element\\Type\\CheckboxType' && field.type !== 'App\\FormBuilder\\Type\\PortalCheckboxType') || this.data[field.name] === true) {
                        formData.append('form[' + field.name + ']', this.data[field.name]);
                    }
                });
                return apiClient.post('/submit-form/'+this.contentType+'/'+this.id, formData).then(response => {
                    if (response.status === 200) {
                        this.$store.dispatch('showSnackbar', {color: 'success', text: 'Formulier verzonden'});
                        this.isSuccess = true;
                    } else {
                        console.error(response.data.error);
                        this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige gegevens'});
                        this.isSubmitted = false;
                    }
                }).catch(() => {
                    this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige gegevens'});
                    this.isSubmitted = false;
                });
            }
        },
    },
}
</script>

<style scoped>

</style>
