<template>
    <v-card>
        <v-card-title>
            <router-link :to="{ 'name': 'Calendar' }">
                <v-icon
                    class="mr-3"
                    color="primary"
                >
                    fal fa-calendar-alt
                </v-icon>Agenda
            </router-link>
        </v-card-title>
        <v-calendar
            v-model="selectedDate"
            :event-color="getEventColor"
            :events="filteredEvents"
            data-weekdays="[1,2,3,4,5,6,0]"
            type="month"
            @change="loadEvents"
            @click:event="showEvent"
        ></v-calendar>
    </v-card>
</template>

<script>
import {DateTime} from "luxon";

export default {
    name: "Agenda",
    mounted() {
        const date = DateTime.local();
        this.selectedDate = date.toISODate();
        //this.setToday();
    },
    data: () => ({
        selectedDate: '',
        isLoading: true,
        events: [],
    }),
    computed: {
        filteredEvents() {
            return this.events;
            // return this.events.filter(event => event.type !== 'specialDate');
        }
    },
    methods: {
        setToday() {
            const date = DateTime.local();
            this.selectedDate = date.toISODate();
        },
        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        loadEvents({start, end}) {
            this.isLoading = true;
            this.$store.dispatch('getEvents', {start: start.date, end: end.date}).then(events => {
                this.events = events;
            }).finally(() => {
                this.isLoading = false;
            })
        },
        getEventColor(event) {
            return this.$store.getters.getEventColor(event);
        },
        showEvent(event) {
            if (event.event.user) {
                this.$router.push({name: 'UserDetail', params: {slug: event.event.user.slug}});
            } else {
                this.$router.push({name: 'EventDetail', params: {slug: event.event.event.slug}});
            }
        }
    },
}
</script>

<style scoped>

</style>
