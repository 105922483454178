<template>
    <v-list shaped>
        <NavigationItem v-for="page in menuPages" :page="page" :key="page.slug"></NavigationItem>
    </v-list>
</template>

<script>
import NavigationItem from "./NavigationItem";

export default {
    name: "Navigation",
    components: {NavigationItem},
    mounted () {
        this.$store.dispatch('getMenuPages')
    },
    computed: {
        menuPages () {
            return this.$store.state.pages.menuPages;
        }
    }
}
</script>

<style scoped>

</style>
