<template>
    <v-container>
        <h1 class="text-h1">Nieuws</h1>
        <v-row v-for="item in paginatedData" :key="item.id">
            <v-col>
                <v-card
                    flat
                    class="news-card"
                >
                    <v-row class="align-center flex-wrap">
                        <v-col sm="12" md="4" lg="3" class="pb-0">
                            <v-img
                                v-if="item.image"
                                :src="item.image.thumbnail"
                                max-width="100%"
                                :alt="item.title"
                                class="d-sm-none d-md-flex"
                            ></v-img>
                            <v-img
                                v-if="item.image"
                                :src="item.image.thumbnail"
                                max-height="200px"
                                :alt="item.title"
                                class="d-none d-sm-flex d-md-none"
                            ></v-img>
                        </v-col>
                        <v-col sm="12" md="8" lg="9">
                            <div class="content">
                                <v-card-subtitle class="pb-0">
                                    {{ item.date|luxon('dd-MM-yyyy') }}
                                </v-card-subtitle>
                                <v-card-title class="pt-2">
                                    <router-link :to="{name: 'NewsDetail', params: {slug: item.slug}}">{{
                                            item.title
                                        }}
                                    </router-link>
                                </v-card-title>
                                <v-card-text>
                                    <div class="mb-4">{{ item.summary }}</div>
                                    <div class="d-flex align-center">
                                        <v-btn class="btn-decorative"
                                               :to="{name: 'NewsDetail', params: {slug: item.slug}}" elevation="0">
                                            Lees meer
                                            <v-icon small class="ml-2">fal fa-arrow-right</v-icon>
                                        </v-btn>
                                        <UserChip v-if="item.author" :user="item.author" class="ml-auto"></UserChip>
                                    </div>
                                </v-card-text>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-pagination v-model="pagination.pageNumber" :length="pages" :total-visible="10" v-if="items.length > pagination.itemsPerPage"
                      @input="nextPage" class="mt-5 mb-5"></v-pagination>
    </v-container>
</template>

<script>
import UserChip from "@/components/UserChip";

export default {
    name: "NewsList",
    components: {UserChip},
    mounted() {
        this.$store.dispatch('getNewsItems')

        if (localStorage.pageContext === 'news') {
            this.pagination.pageNumber = parseInt(localStorage.page);
        }
        localStorage.page = this.pagination.pageNumber;
        localStorage.pageContext = 'news';
    },
    data: () => ({
        pagination: {
            pageNumber: 1,
            itemsPerPage: 8
        }
    }),
    watch: {
        'pagination.pageNumber' () {
            localStorage.page = this.pagination.pageNumber;
            localStorage.pageContext = 'news';
        },
    },
    computed: {
        items() {
            return this.$store.state.news.newsItems;
        },
        pages() {
            return Math.ceil(this.items.length / this.pagination.itemsPerPage)
        },
        paginatedData() {
            const start = this.pagination.pageNumber * this.pagination.itemsPerPage - this.pagination.itemsPerPage, //sets the correct start on data
                end = start + this.pagination.itemsPerPage;
            return this.items.slice(start, end);
        }
    },
    methods: {
        nextPage(page) {
            this.pagination.pageNumber = page;
        }
    }
}
</script>

<style scoped>

</style>
