import apiClient from "@/api/apiClient";
import { DateTime, Interval } from "luxon";

// initial state
const state = {
    users: [],
}

// getters
const getters = {
    getSpecialDates: (state) => (maxResults) => {
        let today = DateTime.local();
        today = today.set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
        // we bouwen een array met daarin samengevoegd de geboortedata en jubilea data
        let events = [
            // we loopen door users
            ...state.users
                // waarvoor een geboortdatum staat ingesteld
                .filter(user => user.birthday)
                .map(user => {
                    // pak de basis datum
                    let base = DateTime.fromISO(user.birthday)

                    // bereken wanneer de volgende verjaardag is
                    let next = DateTime.fromISO(user.birthday)
                    next = next.set({year: today.year });
                    if (next < today) {
                        next = next.set({year: today.year+1 });
                    }

                    // nu weten we de leeftijd
                    let age = Interval.fromDateTimes(base, next).length('years');

                    // en berekenen we ook hoeveel dagen het is
                    let numDays = Interval.fromDateTimes(today, next).length('days');

                    // en bouwen we het result object
                    return {
                        label: age+' jaar',
                        date: next.toString(),
                        numDays: numDays,
                        type: 'birthday',
                        isToday: next.toFormat('yyyy MM dd') === today.toFormat('yyyy MM dd'),
                        ...user
                    }
                }),
            ...state.users
            .filter(user => user.date_of_employment)
            .map(user => {
                // pak de basis datum
                let base = DateTime.fromISO(user.date_of_employment)

                // bereken wanneer het volgende jubileum
                const dates = [
                    { age: 12.5, year: 12, day: 182 },
                    { age: 25, year: 25, day: 0 },
                    { age: 40, year: 40, day: 0 },
                    { age: 100, year: 100, day: 0 },
                ];

                let age = null;
                let next =null;
                for (let i = 0; i < dates.length; i++) {
                    let date = dates[i];
                    let nextDate = next = DateTime.fromISO(user.date_of_employment);
                    nextDate = nextDate.set({year: base.year + date.year, day: base.day + date.day});
                    age = date.age;
                    if (nextDate >= today) {
                        next = nextDate;
                        break;
                    }
                }

                // en berekenen we ook hoeveel dagen het is
                let numDays = Interval.fromDateTimes(today, next).length('days');

                // en bouwen we het result object
                return {
                    label: age+' jaar in dienst',
                    date: next.toString(),
                    numDays: numDays,
                    type: 'date_of_employment',
                    isToday: next.toFormat('yyyy MM dd') === today.toFormat('yyyy MM dd'),
                    ...user
                }
            })
        ].sort((a, b) => {
            // Sorteer alles op aantal dagen tot het volgende evenement
            return a.numDays - b.numDays
        });

        // En uiteindelijk ook nog even limitten op de max
        return events.slice(0, maxResults);
    },
    getSpecialDateEvents: (state) => (start, end) => {
        const dtStart = DateTime.fromISO(start);
        const dtEnd = DateTime.fromISO(end);
        const range = Interval.fromDateTimes(dtStart, dtEnd)
        const today = DateTime.local();
        return [
            // alle verjaardagen
            ...state.users
                .filter(user => user.birthday)
                .map(user => {
                    // pak de basis datum
                    let base = DateTime.fromISO(user.birthday).set({year: dtStart.year});

                    if (!range.contains(base)) {
                        return null;
                    }

                    return {
                        name: 'Verjaardag ' + user.title,
                        start: base.toJSDate(),
                        end: base.toJSDate(),
                        type: 'specialDate',
                        timed: false,
                        user: user,
                    };


                })
                // vorige functie kan null terug geven.
                .filter(userOrNull => (userOrNull !== null)),
            // alle verjaardagen
            ...state.users
                .filter(user => user.date_of_employment)
                .map(user => {
                    // pak de basis datum
                    let base = DateTime.fromISO(user.date_of_employment)

                    // bereken wanneer het volgende jubileum
                    const dates = [
                        { age: 12.5, year: 12, day: 182 },
                        { age: 25, year: 25, day: 0 },
                        { age: 40, year: 40, day: 0 },
                        { age: 100, year: 100, day: 0 },
                    ];

                    let next = null;
                    for (let i = 0; i < dates.length; i++) {
                        let date = dates[i];
                        let nextDate = next = DateTime.fromISO(user.date_of_employment);
                        nextDate = nextDate.set({year: base.year + date.year, day: base.day + date.day});
                        if (nextDate >= today) {
                            next = nextDate;
                            break;
                        }
                    }

                    if (!range.contains(next)) {
                        return null;
                    }

                    return {
                        name: 'Jubileum ' + user.title,
                        start: next.toJSDate(),
                        end: next.toJSDate(),
                        type: 'specialDate',
                        timed: false,
                        user: user,
                    };


                })
                // vorige functie kan null terug geven.
                .filter(userOrNull => (userOrNull !== null))
        ]
    }
}

// actions
const actions = {
    getUsers({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.users.length >= 1) {
            return state.users;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/users')
            .then(response => {
                commit('SET_USERS', response.data);
                return response.data;
            })
    },
    getUser({state, dispatch}, { slug }) {

        let findBySlug = (users) => {
            return users.find(user => user.slug === slug);
        }

        if (state.users.length === 0) {
            return dispatch('getUsers').then(users => {
                return findBySlug(users);
            });
        } else {
            return findBySlug(state.users);
        }
    },
}

// mutations
const mutations = {
    SET_USERS(state, users) {
       state.users = users;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
