<template>
    <v-container :fluid="$vuetify.breakpoint.mdAndDown" class="pa-0 calendar">
        <h1 class="text-h1">Agenda</h1>
        <v-row class="fill-height">
            <v-col class="calendar-col">
                <v-sheet>
                    <v-toolbar
                        height="64"
                        flat>
                        <v-btn
                            class="mr-3 d-none d-md-flex"
                            color="primary"
                            @click="setToday()"
                            :small="$vuetify.breakpoint.smAndDown"
                        >
                            Vandaag
                        </v-btn>

                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="prev()"
                        >
                            <v-icon small>
                                fal fa-chevron-left
                            </v-icon>
                        </v-btn>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="next()"
                        >
                            <v-icon small>
                                fal fa-chevron-right
                            </v-icon>
                        </v-btn>

                        <v-toolbar-title v-if="$refs.calendar" class="ml-3">
                          {{ $refs.calendar.title }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>
                        <v-btn-toggle v-model="calendarView" v-if="$vuetify.breakpoint.smAndUp">
                            <v-btn :x-small="$vuetify.breakpoint.smAndDown" :small="$vuetify.breakpoint.mdAndUp" value="day">Dag</v-btn>
                            <v-btn :x-small="$vuetify.breakpoint.smAndDown" :small="$vuetify.breakpoint.mdAndUp" value="week">Week</v-btn>
                            <v-btn :x-small="$vuetify.breakpoint.smAndDown" :small="$vuetify.breakpoint.mdAndUp" value="month">Maand</v-btn>
                        </v-btn-toggle>
                    </v-toolbar>
                </v-sheet>
                <v-sheet class="calendar-sheet">
                    <v-calendar
                        ref="calendar"
                        v-model="selectedDate"
                        data-weekdays="[1,2,3,4,5,6,0]"
                        :type="calendarView"
                        :events="filteredEvents"
                        :event-more="false"
                        :event-color="getEventColor"
                        @change="loadEvents"
                        @click:event="showEvent"
                    ></v-calendar>
                </v-sheet>
            </v-col>
            <v-col class="calendar-meta-col">
                <v-sheet v-if="false" class="d-none d-md-block">
                    <v-calendar></v-calendar>
                </v-sheet>

                <v-card class="pa-0" elevation="0">
                    <v-list dense class="pa-0">
                        <v-list-item>
                            <v-checkbox color="secondary" v-model="activeCategories" value="specialDate" label="Verjaardagen & Jubilea" hide-details></v-checkbox>
                        </v-list-item>
                        <v-list-item>
                            <v-checkbox color="purple lighten-1" v-model="activeCategories" value="event" label="Activiteiten" hide-details></v-checkbox>
                        </v-list-item>
                        <v-list-item>
                            <v-checkbox color="accent" v-model="activeCategories" value="free" label="ATV dagen" hide-details></v-checkbox>
                        </v-list-item>
                        <v-list-item>
                            <v-checkbox color="red darken-4" v-model="activeCategories" value="holiday" label="Feestdagen" hide-details></v-checkbox>
                        </v-list-item>
                        <v-list-item>
                            <v-checkbox color="primary" v-model="activeCategories" value="freeOptional" label="Optionele vrije dagen" hide-details></v-checkbox>
                        </v-list-item>
                        <v-list-item>
                            <v-checkbox color="cyan darken-1" v-model="activeCategories" value="holidayDay" label="Vakantiedagen" hide-details></v-checkbox>
                        </v-list-item>
                        <v-list-item>
                            <v-checkbox color="primary darken-4" v-model="activeCategories" value="other" label="Overige" hide-details></v-checkbox>
                        </v-list-item>
                    </v-list>
                </v-card>

                <div class="mt-5" v-if="files.length > 0">
                    <h1>Jaarkalender</h1>
                    <v-list dense class="pa-0">
                        <v-list-item v-for="file in files" :key="file.id"><a :href="file.url">{{ file.name }}</a></v-list-item>
                    </v-list>
                </div>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { DateTime } from "luxon";
import {mapState} from "vuex";
export default {
    name: "Calendar",
    mounted() {
        if (!this.selectedDate) {
            const date = DateTime.local();
            this.selectedDate = date.toISODate();
        }
    },
    data: () => ({
        isLoading: true,
        calendarView: 'month',
        events: [],
    }),
    computed: {
        activeCategories: {
            get () {
                return this.$store.state.calendar.activeCategories;
            },
            set (value) {
                this.$store.commit('SET_ACTIVE_CATEGORIES', value);
            }
        },
        filteredEvents () {
            return this.events.filter(event => this.activeCategories.includes(event.type));
        },
        selectedDate: {
            get () {
                return this.$store.state.calendar.selectedDate;
            },
            set (value) {
                this.$store.commit('SET_SELECTED_DATE', value);
            }
        },
        ...mapState({
            files: state => state.calendar.files
        })
    },
    methods: {
        setToday() {
            const date = DateTime.local();
            this.selectedDate = date.toISODate();
        },
        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        loadEvents({start, end}) {
            this.isLoading = true;
            this.$store.dispatch('getEvents', {start: start.date, end: end.date}).then(events => {
                this.events = events;
            }).finally(() => {
                this.isLoading = false;
            })
        },
        getEventColor(event) {
            return this.$store.getters.getEventColor(event);
        },
        showEvent(event) {
            if (event.event.user) {
                this.$router.push({name: 'UserDetail', params: { slug: event.event.user.slug }});
            } else {
                this.$router.push({name: 'EventDetail', params: { slug: event.event.event.slug }});
            }
        }
    },
}
</script>

<style scoped>

</style>
