<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-row class="login-form elevation-12">
                <v-col sm="5" class="primary intro d-none d-sm-flex">
                    <h1>Wachtwoord reset</h1>
                    <p class="px-2 mb-4">Vul een nieuw wachtwoord in voor uw Intranet Bouwmij-Janssen-account.</p>
                    <v-btn outlined color="secondary" small :to="{name: 'Login'}">Terug naar inloggen</v-btn>
                </v-col>
                <v-col sm="7" class="pa-0">
                    <div class="animated-logo mx-5">
                        <lottie-animation
                            path="logo.json"
                            :loop="false"
                        />
                    </div>
                    <v-form ref="form" @submit.prevent="resetPassword">
                        <v-card class="elevation-0">
                            <v-card-text>
                                <v-alert type="error" :value="error">{{ error }}</v-alert>
                                <v-text-field
                                    type="password"
                                    prepend-icon="far fa-key"
                                    v-model="password"
                                    label="Nieuw wachtwoord"
                                    :rules="passwordRules"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    type="password"
                                    prepend-icon="far fa-key"
                                    v-model="repeatPassword"
                                    label="Herhaal wachtwoord"
                                    :rules="repeatPasswordRules"
                                    required
                                ></v-text-field>
                            </v-card-text>
                            <v-card-actions class="justify-center pb-3">
                                <v-btn large type="submit" color="primary">
                                    <v-icon
                                        small
                                        class="mr-2"
                                      >
                                        fal fa-sign-in
                                    </v-icon>
                                    Wachtwoord wijzigen
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-col>
            </v-row>
        </v-layout>
    </v-container>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
    name: "ResetPassword",
    data: () => ({
        password: '',
        repeatPassword: '',
        passwordRules: [
            v => !!v || 'Verplicht',
            v => v.length >= 6 || 'Minimaal 6 karakters',
        ],
        error: null,
        isSubmitted: false,
    }),
    components: {
      LottieAnimation
    },
    computed: {
        repeatPasswordRules () {
            return [
                v => !!v || 'Verplicht',
                v => v === this.password || 'Wachtwoorden komen niet overeen',
                v => v.length >= 6 || 'Minimaal 6 karakters',
            ];
        }
    },
    methods: {
        resetPassword() {
            // Check if form is valid
            if (!this.isSubmitted && this.$refs.form.validate()) {
                this.isSubmitted = true;
                // Submit data to auth store
                this.$store.dispatch('resetPassword', {
                    token: this.$route.params.token,
                    password: this.password,
                }).then((success) => {
                    if (success) {
                        this.$store.dispatch('showSnackbar', {color: 'success', text: 'Wachtwoord gewijzigd'});
                        return this.$router.push({name: 'Login'});
                    } else {
                        this.isSubmitted = false;
                        return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige gegevens'});
                    }
                }).catch((e) => {
                    this.isSubmitted = false;
                    console.error(e);
                    return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Serverfout'});
                })
            }
        }
    }

}
</script>

<style scoped>

</style>
