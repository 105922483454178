// initial state
import apiClient from "@/api/apiClient";

const state = {
    isLoggedIn: false,
    user: null
}

// getters
const getters = {

}

// actions
const actions = {
    login({commit}, { username, password, _remember_me }) {
        commit('SET_AUTH_STATE', { isLoggedIn: false})
        return apiClient.post('/login', JSON.stringify({ username, password, _remember_me }))
            .then(response => {
                if (response.status === 200) {
                    commit('SET_AUTH_STATE', {isLoggedIn: true, user: response.data })
                    return true;
                } else {
                    console.error(response.data.error);
                    return false;
                }
            })
    },
    forgotPassword(_, { username }) {
        let formData = new FormData();
        formData.append('username', username);
        return apiClient.post('/forgot-password', formData)
            .then(response => {
                if (response.status === 200) {
                    return true;
                } else {
                    console.error(response.data.error);
                    return false;
                }
            })
    },
    resetPassword(_, { token, password }) {
        let formData = new FormData();
        formData.append('token', token);
        formData.append('password', password);
        return apiClient.post('/reset-password', formData)
            .then(response => {
                if (response.status === 200) {
                    return true;
                } else {
                    console.error(response.data.error);
                    return false;
                }
            })
    },
    updatePassword(_, { password }) {
        let formData = new FormData();
        formData.append('password', password);
        return apiClient.post('/update-password', formData)
            .then(response => {
                if (response.status === 200) {
                    return true;
                } else {
                    console.error(response.data.error);
                    return false;
                }
            })
    },
    updatePersonalInfo(_, formData) {
        return apiClient.post('/update-personal-info', formData).then(() => {
            return true;
        })
    },
    logout({commit}) {
        // TODO: Ook cookie verwijderen
        commit('CLEAR_AUTH_STATE');
        commit('SET_LOGIN_REDIRECT_ROUTE', null);
    },
    updateUser({commit, state}, { user }) {
        commit('SET_AUTH_STATE', {isLoggedIn: state.isLoggedIn, user: user })
    },
}

// mutations
const mutations = {
    SET_AUTH_STATE(state, { isLoggedIn, user }) {
        state.isLoggedIn = isLoggedIn;
        state.user = user;
    },
    CLEAR_AUTH_STATE(state) {
        state.isLoggedIn = null;
        state.user = null;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
