<template>
    <v-container v-if="newsItem">
<!--        <v-row>-->
<!--            <v-col>-->
<!--                <v-card>-->
<!--                    <pre>{{ newsItem }}</pre>-->
<!--                </v-card>-->
<!--            </v-col>-->
<!--        </v-row>-->
        <v-row class="align-center">
            <v-col md="3" class="order-md-2" v-if="newsItem.image">
                <v-img
                    :src="newsItem.image.thumbnail"
                    max-width="100%"
                    :alt="newsItem.title"
                    class="d-sm-none d-md-flex"
                    @click="index = 0"
                    style="cursor: pointer;"
                ></v-img>
                <v-img
                    :src="newsItem.image.thumbnail"
                    max-height="150px"
                    :alt="newsItem.title"
                    class="d-none d-sm-flex d-md-none"
                    @click="index = 0"
                    style="cursor: pointer;"
                ></v-img>
            </v-col>
            <v-col md="9" class="order-md-1">
                <span class="text--disabled">{{ newsItem.date|luxon('dd-MM-yyyy') }}</span>
                <h1 class="text-h1 mb-3">{{ newsItem.title }}</h1>
            </v-col>
        </v-row>
        <hr class="mt-5 mb-4" />
        <ContentEditorContent :content="newsItem.content" class="news-content"></ContentEditorContent>
        <v-btn v-if="newsItem.event" class="primary" :to="{name: 'EventDetail', params: { slug: newsItem.event.slug }}">
            <v-icon small class="mr-2">fal fa-calendar-alt</v-icon>
            Bekijk in agenda
        </v-btn>
        <v-alert class="mt-3" v-if="newsItem.form_submission === null && isExpired" type="warning">
            <span>De uiterste aanmelddatum is verstreken</span>
        </v-alert>
        <SignupForm
            v-if="newsItem.form_configuration"
            :entries="newsItem.form_entries"
            :multiple-entries="newsItem.formMultipleEntries"
            :configuration="newsItem.form_configuration"
            :submission="newsItem.form_submission"
            :expiration-date="newsItem.expirationDate"
            :show-entries="newsItem.showFormEntries"
            content-type="portal_news" :id="newsItem.form_id"
            :is-anonymous="newsItem.anonymousForm"
            :form-title="newsItem.formTitle ? newsItem.formTitle : 'Aanmelden'"
            :form-subtitle="newsItem.formSubtitle ? newsItem.formSubtitle : 'Vul onderstaand formulier in om je aan te melden.'"
            :form-thanks="newsItem.formThanks ? newsItem.formThanks : 'Bedankt voor het aanmelden!'"
        ></SignupForm>
        <hr />
        <BackButton :route="{name: 'NewsList'}" />
        <CoolLightBox
            v-if="newsItem.image"
            :items="[newsItem.image.url]"
            :index="index"
            @close="index = null"
            :slideshow-color-bar="this.$vuetify.theme.defaults.light.primary"
            :overlay-color="'rgba(255,255,255,1)'"
        >
        </CoolLightBox>
    </v-container>
</template>

<script>
import BackButton from "../components/BackButton";
import SignupForm from "../components/SignupForm";
import CoolLightBox from 'vue-cool-lightbox';
import ContentEditorContent from "../components/ContentEditorContent";

export default {
    name: "NewsDetail",
    components: {
        ContentEditorContent,
        BackButton,
        SignupForm,
        CoolLightBox,
    },
    mounted () {
        this.loadNewsItem();
        this.isExpired()
    },
    data: () => ({
        newsItem: null,
        index: null,
    }),
    watch: {
        '$route.params.slug' () {
            this.loadNewsItem();
        }
    },
    computed: {
        isExpired() {
            let date = new Date().getTime()
            let expDate = new Date(this.newsItem.expirationDate).getTime()
            if (!expDate) {
                return
            }

            return !(expDate === 0 || date < expDate);
        },
    },
    methods: {
        loadNewsItem() {
            let slug = this.$route.params.slug;
            this.$store.dispatch('getNewsItem', { slug: slug }).then(newsItem => {
                this.newsItem = newsItem;
            })
        },
    }
}
</script>

<style scoped>

</style>
