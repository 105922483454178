import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import createPersistedState from 'vuex-persistedstate'

import app from './modules/app'
import users from './modules/users'
import news from './modules/news'
import auth from './modules/auth'
import calendar from './modules/calendar'
import projects from './modules/projects'
import improvements from './modules/improvements'
import photoalbums from './modules/photoalbums'
import pages from './modules/pages'
import registrationForms from "./modules/registrationForms";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
let plugins = []
if (debug) {
    plugins.push(createLogger)
}
plugins.push(createPersistedState({
    key: process.env.VUE_APP_VUEX_STORAGE_NAME,
    paths: [
        'auth.isLoggedIn',
        'auth.user',
    ]
}))


export default new Vuex.Store({
    modules: {
        app,
        users,
        news,
        auth,
        calendar,
        projects,
        improvements,
        photoalbums,
        pages,
        registrationForms,
    },
    strict: debug,
    plugins: plugins
})
