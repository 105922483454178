<template>
    <v-navigation-drawer app v-model="drawer" v-if="isLoggedIn" class="primary" dark>
        <div class="navigation-drawer-logo">
            <v-img
                contain
                height="50"
                src="../assets/images/bouwmij-janssen-logo.svg"
            ></v-img>
        </div>
        <v-list-item class="mb-5">
            <v-list-item-content>
                <v-list-item-subtitle>
                    <span class="float-right">v{{ appVersion }}</span>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-list
            dense
            nav
            class="mt-4"
        >
            <v-list-item
                v-for="item in items"
                :key="item.title"
                :to="{name: item.route}"
                :exact="item.exact"
                link
            >
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <div class="bottom mt-auto">
            <v-btn small @click="onClickPasswordChangeButton" class="mb-3">
                <v-icon
                    small
                    class="mr-2"
                >
                    fal fa-key
                </v-icon>
                Wachtwoord wijzigen
            </v-btn>
            <v-btn small @click="onClickLogoutButton">
                <v-icon
                    small
                    class="mr-2"
                >
                    fal fa-sign-out
                </v-icon>
                Uitloggen
            </v-btn>
        </div>
    </v-navigation-drawer>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: "NavigationDrawer",
    data: () => ({
        appVersion: process.env.VUE_APP_VERSION,
    }),
    computed: {
        drawer: {
            get() {
                return this.$store.state.app.drawer
            },
            set(value) {
                this.$store.dispatch('toggleNavigationDrawer', value);
            }
        },
        ...mapState({
            items: state => state.app.navigationItems,
            isLoggedIn: state => state.auth.isLoggedIn
        })
    },
    methods: {
        onClickPasswordChangeButton() {
            this.$router.push({name:'ChangePassword'});
        },
        onClickLogoutButton() {
            this.$emit('show-dialog', true)
        }
    }
}
</script>

<style scoped>

</style>
