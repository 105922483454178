<template>
    <v-container>
        <v-row align="center">
            <v-col>
                <h1 class="text-h1">Projecten</h1>
            </v-col>
<!--            <v-col class="text-sm-right">-->
<!--                <v-btn-toggle class="btn-toggle-decorative" color="primary" v-model="status">&lt;!&ndash; TODO: OOk in routing actieve tab bijhouden &ndash;&gt;-->
<!--                    <v-btn value="in-progress">In uitvoering</v-btn>-->
<!--                    <v-btn value="in-development">In ontwikkeling</v-btn>-->
<!--                </v-btn-toggle>-->
<!--            </v-col>-->
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <v-data-table
                        :headers="headers"
                        :loading="isLoading"
                        :items="filteredItems"
                        :page.sync="page"
                        class="project-list-table"
                    >
                        <template v-slot:item.name="{ item }">
                            <router-link :to="{name: 'ProjectDetail', params: { id: item.id }}">{{item.name}}</router-link>
                            <div class="project-description" v-html="item.description" v-if="item.description"/>
                        </template>
                        <template v-slot:item.project_leads="{ item }">
                            <v-chip-group column>
                                <UserChip v-for="(lead, index) in item.project_leads" :user="lead" :key="index"></UserChip>
                            </v-chip-group>
                        </template>
                        <template v-slot:item.planners="{ item }">
                            <v-chip-group column>
                                <UserChip v-for="(planner, index) in item.planners" :user="planner" :key="index"></UserChip>
                            </v-chip-group>
                        </template>
                        <template v-slot:item.foremans="{ item }">
                            <v-chip-group column>
                                <UserChip v-for="(foreman, index) in item.foremans" :user="foreman" :key="index"></UserChip>
                            </v-chip-group>
                        </template>
                        <template v-slot:item.coordinators="{ item }">
                            <v-chip-group column>
                                <UserChip v-for="(coordinator, index) in item.coordinators" :user="coordinator" :key="index"></UserChip>
                            </v-chip-group>
                        </template>
                        <template v-slot:item.contacts="{ item }">
                            <v-chip-group column>
                                <UserChip v-for="(contact, index) in item.contacts" :user="contact" :key="index"></UserChip>
                            </v-chip-group>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapState} from 'vuex';
import UserChip from "@/components/UserChip";
export default {
    name: "ProjectList",
    components: {UserChip},
    mounted() {
        this.loadProjects();

        if (localStorage.pageContext === 'project') {
            this.page = parseInt(localStorage.page);
        }
        localStorage.page = this.page;
        localStorage.pageContext = 'project';
    },
    data: () => ({
        isLoading: true,
        status: 'in-progress',
        page: 1,
    }),
    watch: {
        page () {
            localStorage.page = this.page;
            localStorage.pageContext = 'project';
        },
    },
    computed: {
        headers () {
            let headers = [
                {text: 'Projectnummer', value: 'project_no'},
                {text: 'Werkomschrijving', value: 'name'},
            ];

            // switch(this.status) {
            //     case 'in-development':
            //         headers.push({text: 'Contactpersonen', value: 'contacts'})
                //     break;
                // case 'in-progress':
                    headers.push({text: 'Projectleiders', value: 'project_leads'})
                    headers.push({text: 'Werkvoorbereiders', value: 'planners'})
                    headers.push({text: 'Uitvoerders', value: 'foremans'})
                    headers.push({text: 'Klantcoördinators', value: 'coordinators'})
            //         break;
            // }

            return headers;
        },
        filteredItems () {
            return this.projects;
            // return this.projects.filter(p => p.status === this.status);
        },
        ...mapState({
            projects: state => state.projects.projects
        })
    },
    methods: {
        loadProjects() {
            this.isLoading = true;
            this.$store.dispatch('getProjects')
                .finally(() => {
                    this.isLoading = false
                })
        }
    }
}
</script>

<style scoped>

</style>
